<template>
  <section class="estimate_header">
    <div
      class="tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-between"
    >
      <div
        v-if="showSaveAndLoadButton"
        class="tw-flex tw-gap-2 tw-items-center justify-start"
      >
        <span class="tw-text-lg tw-font-bold lg:tw-text-base tw-text-start">{{
          isChangeRequest
            ? "Change Request Job Estimates"
            : "Iknowa Job Estimates"
        }}</span>
      </div>
      <div class="tw-flex tw-gap-4" v-if="!isChangeRequest">
        <Button
          class="!tw-w-[auto]"
          v-if="showTourButton"
          variant="secondary"
          label="🎉 take a tour of iknowa estimations"
        />
      </div>
    </div>
    <div class="tw-w-full">
      <div
        class="tw-flex tw-flex-col tw-gap-2 sm:tw-gap-3"
        v-if="showEstimateDetailsHeader"
      >
        <h4
          class="tw-text-2xl tw-text-[#0C0F4A] font-bold leading-8 lg:tw-text-lg text-left"
        >
          {{ jobDetails?.name }}
        </h4>

        <div
          class="tw-flex tw-justify-between tw-items-center tw-flex-wrap md:tw-flex-col md:tw-items-start"
        >
          <div class="tw-flex tw-gap-[26px] tw-items-center">
            <div class="tw-flex tw-gap-4 tw-items-center">
              <CommercialChip />
            </div>

            <LabelWithValue
              class="!tw-w-[auto] !tw-border-0"
              :noBg="true"
              :justifyStart="true"
              label="Project ID:"
              :data="jobDetails?.refId"
            />
          </div>

          <AddContractorButton
            v-if="showInviteSubcontractorButton && !isReviewCompare"
            @click="onOpenContractorModal"
          />
        </div>

        <div class="tw-flex tw-items-center tw-justify-between">
          <div class="tw-flex tw-gap-4 tw-items-center tw-justify-between">
            <div
              class="tw-flex tw-gap-4 tw-items-center sm:tw-flex-col sm:tw-items-start sm:tw-gap-3"
            >
              <EmergencyChip
                v-if="jobDetails?.type === ProjectTypesEnum.EMERGENCY"
              />
              <StandardChip
                v-if="jobDetails?.type === ProjectTypesEnum.STANDARD"
              />
              <RoutineChip
                v-if="jobDetails?.type === ProjectTypesEnum.ROUTINE"
              />
            </div>
          </div>

          <div
            class="tw-bg-white tw-rounded-[8px] tw-p-[16px] tw-text-left tw-mt-3 tw-text-[#0C0F4A]"
            v-if="
              jobDetails?.type === ProjectTypesEnum.EMERGENCY &&
              emergencyCallOutDetails
            "
          >
            <div
              class="tw-text-[14px] tw-pb-[12px] tw-border-b-dashed-custom tw-mb-[12px]"
            >
              Rates
            </div>
            <div
              class="tw-grid tw-gap-3 rsm:tw-grid-cols-2 tw-text-[12px] rsm:tw-text-[15px]"
            >
              <div class="tw-grid tw-gap-[6px]">
                <p><strong>Emergency call out fee </strong></p>
                <p>£{{ emergencyCallOutDetails.emergencyCallOutFee }}</p>
              </div>
              <div class="tw-grid tw-gap-[6px]">
                <p><strong>Emergency call out rate</strong></p>
                <p>
                  £{{ emergencyCallOutDetails.emergencyCallOutRate }} / hour
                </p>
              </div>
            </div>
          </div>

          <Button
            v-if="isShowSubmitButton && !isChangeRequest"
            label="Submit Estimate"
            class="!tw-w-auto"
            @click="onSubmitEstimate"
            :disabled="
              !formData?.id ||
              isLockTemplateModifiedChecker ||
              isEstimateSubmit ||
              currentJobDetails?.isHistoricProject ||
              !isUserHasEstimateEditAccess
            "
          >
            <template
              v-if="isLockTemplateModifiedChecker || isEstimateSubmit"
              v-slot:postfix
            >
              <img src="@/assets/icons/lock.svg" alt="" />
            </template>
          </Button>
          <Button
            v-if="isShowSubmitButton && isChangeRequest"
            label="Preview Estimate"
            class="!tw-w-auto"
            variant="secondary"
            @click="onPreviewSubmitEstimate"
            :disabled="isDisabledSubmitBtn"
          >
            <!-- <template
              v-if="isLockTemplateModifiedChecker || isEstimateSubmit"
              v-slot:postfix
            >
              <img src="@/assets/icons/lock.svg" alt="" />
            </template> -->
          </Button>
        </div>
      </div>
      <AddContractorButton
        v-if="isClientEstimate && !changeRequestFlow"
        @click="onOpenContractorModal"
      />
    </div>

    <!-- MODALS -->

    <InviteContractorModal
      v-if="state.contractorModal"
      @on-close="onCloseContractorModal"
      @openOutsiderInviteModel="onOpenOutsiderInviteModal"
      :selectedContractorList="selectedContractorList"
      :projectData="jobDetails"
      @onclickContinue="onclickInviteContractorContinue"
    />

    <ContractorInvitationFormModal
      v-if="state.contractInvitationFormModal"
      :selectedContractorList="selectedContractorList"
      :projectData="jobDetails"
      @on-close="onCloseContractInvitationFormModal"
      @on-back="onInvitationFormBack"
      @on-send-invitation="onSendInvitation"
    />

    <InviteContractorOutSideIknowa
      v-if="state.outSideContractorModal"
      :onCloseForm="onCloseOutSideContractorModal"
      @on-send-invitation="onSendInvitation"
    />

    <InviteConfirmationModal
      v-if="state.inviteConfirmationModal"
      :inviteUserEmail="inviteUserEmail"
      @on-close="onCloseInviteConfirmationModal"
    />
    <FirstEstimateWalkthroughDialog
      v-if="showWalkthroughModal"
      @on-close="showWalkthroughModal = false"
    />
  </section>
</template>
<script setup>
import { onBeforeMount, reactive } from "vue";
import FirstEstimateWalkthroughDialog from "@/core/uni-components/FirstEstimateWalkthroughDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import LabelWithValue from "@/core/components/common/LabelWithValue.vue";
import CommercialChip from "@/core/uni-components/Chips/CommercialChip.vue";
import AddContractorButton from "@/modules/jobs/components/estimates/AddContractorButton.vue";
import ContractorInvitationFormModal from "@/modules/jobs/components/modal/ContractorInvitationFormModal.vue";
import InviteConfirmationModal from "@/modules/jobs/components/modal/InviteConfirmationModal.vue";
import JobService from "@/core/services/jobs.service";
import { ref } from "vue";
import { computed } from "vue";
import EmergencyChip from "@/core/uni-components/Chips/EmergencyChip.vue";
import StandardChip from "@/core/uni-components/Chips/StandardChip.vue";
import RoutineChip from "@/core/uni-components/Chips/RoutineChip.vue";
import { ProjectTypesEnum } from "@/core/enums/ProjectsEnum";
import { useStore } from "vuex";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import { JOBS_STORE } from "@/store/modules/jobs";
import { ElMessage } from "element-plus";
import InviteContractorOutSideIknowa from "@/modules/jobs/components/modal/InviteContractorOutSideIknowa.vue";
import InviteContractorModal from "../modal/InviteContractorModal.vue";

const emits = defineEmits([
  "on-save-template",
  "on-load-template",
  "switch-revision",
  "on-submit",
  "on-preview-estimate",
  "on-save-estimation",
]);
const props = defineProps({
  showTourButton: Boolean,
  showSaveAndLoadButton: Boolean,
  showEstimateDetailsHeader: Boolean,
  isClientEstimate: Boolean,
  projectRefId: String,
  jobDetails: Object,

  showInviteSubcontractorButton: {
    type: Boolean,
    default: true,
  },
  isShowTemplateButtons: {
    type: Boolean,
  },

  totalSubTotal: {
    type: Number,
  },
  totalVat: {
    type: Number,
  },
  serviceCharges: {
    type: Number,
  },
  isProperty: Boolean,
  isDIYProjectType: {
    type: Boolean,
    default: false,
  },

  isShowSubmitButton: {
    type: Boolean,
    default: true,
  },
  isChangeRequest: {
    type: Boolean,
    default: false,
  },
  isReviewCompare: {
    type: Boolean,
    default: false,
  },
});

const showWalkthroughModal = ref(false);
const selectedContractorList = ref([]);
const inviteUserEmail = ref(null);
const state = reactive({
  outSideContractorModal: false,
  contractorModal: false,
  contractInvitationFormModal: false,
  inviteConfirmationModal: false,
});
const store = useStore();
const isUserHasEstimateEditAccess = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/isUserHasEstimateEditAccess`]
);
const changeRequestFlow = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getUpdateChangeRequest`].isUpdate
);
const emergencyCallOutDetails = ref(null);
const onSave = () => {
  emits("on-save-template");
};

const isDisabledSubmitBtn = computed(() => {
  if (props.isChangeRequest) return false;
  return true;
});

const onOpenContractorModal = () => {
  if (
    props.isClientEstimate &&
    (!projectDetails.value?.id || !formData.value.id)
  ) {
    if (!formData.value?.id) {
      emits("on-save-estimation");
    }
    if (!projectDetails.value?.id) {
      ElMessage({
        message: "Project Name is Required.",
        type: "error",
      });
      store.dispatch(`${JOB_TEMPLATE_STORE}/setFormError`, {
        field: "projectName",
        value: true,
      });
    }

    return;
  }

  state.contractorModal = true;
};

const onCloseContractorModal = () => {
  state.contractorModal = false;
  selectedContractorList.value = [];
};
const onOpenOutsiderInviteModal = () => {
  state.contractorModal = false;
  state.outSideContractorModal = true;
};

const onclickInviteContractorContinue = (value) => {
  onCloseContractorModal();
  state.contractInvitationFormModal = true;
  selectedContractorList.value = value;
};

const onCloseContractInvitationFormModal = () => {
  state.contractInvitationFormModal = false;
  selectedContractorList.value = [];
};
const onInvitationFormBack = (data) => {
  onCloseContractInvitationFormModal();
  selectedContractorList.value = data;
  onOpenContractorModal();
};
const onSendInvitation = (email) => {
  state.inviteConfirmationModal = true;
  onCloseContractInvitationFormModal();
  onCloseOutSideContractorModal();
  getProjectEstimation();
};

const isSubContractor = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getIsSubContractor`]
);
const projectEstimateInvitation = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getProjectEstimationInvitation`]
);
const projectDetails = computed(
  () => store.getters[`${JOBS_STORE}/getJobDetails`]
);

const formData = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
);

const customerTotal = computed(() => {
  const total = props.totalSubTotal + props.totalVat + props.serviceCharges;

  return props.isDIYProjectType
    ? formData.value?.totalCost || 0
    : Math.round(parseFloat(total) * 100) / 100;
});

const totalGovernment = computed(() => {
  const value = customerTotal.value - parseFloat(formData.value.grantAmount);
  return value.toFixed(2);
});

const isLockTemplateModifiedChecker = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/lockTemplateModifiedChecker`]
);

const isEstimateSubmit = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/isEstimateSubmit`]
);

const currentJobDetails = computed(
  () => store.getters[`${JOBS_STORE}/getJobDetails`]
);

const getProjectEstimation = async () => {
  if (isSubContractor.value) {
    if (projectEstimateInvitation.value[0]?.projectEstimation?.id)
      await store.dispatch(
        `${JOB_TEMPLATE_STORE}/getProjectEstimateByEstimateId`,
        projectEstimateInvitation.value[0]?.projectEstimation?.id
      );
  } else {
    if (projectDetails.value?.id)
      await store.dispatch(
        `${JOB_TEMPLATE_STORE}/getProjectEstimateByProjectId`,
        {
          projectId: projectDetails.value?.id,
          filterStatus: false,
        }
      );
  }
};

const onSubmitEstimate = async () => {
  if (totalGovernment.value <= 0) {
    // eslint-disable-next-line no-undef
    ElMessage.error("total estimation value not be negative.");
  } else {
    emits("on-submit");
  }
};
const onPreviewSubmitEstimate = () => {
  emits("on-preview-estimate");
};

const onCloseInviteConfirmationModal = () => {
  state.inviteConfirmationModal = false;
};

const onCloseOutSideContractorModal = () => {
  state.outSideContractorModal = false;
};
const projectOwner = computed(() => {
  return (
    props.jobDetails?.projectCreator?.firstName +
    " " +
    props.jobDetails?.projectCreator?.lastName
  );
});

onBeforeMount(async () => {
  if (props.jobDetails?.id)
    emergencyCallOutDetails.value =
      await JobService.getEmergencyJobCallOutDetails(props.jobDetails?.id);
});
</script>
<style lang="scss" scoped>
.header-buttons {
  .v-btn {
    height: auto;
    box-shadow: none;
    padding: 7px 22px;
    :deep(.v-btn__content) {
      @include fluidFont(12, 12, 1.2);
      font-weight: 600;
      color: rgba($blueDark, 0.5);
      text-transform: capitalize;
      letter-spacing: 0.4px;
    }
  }
}
</style>
