import {
  UpdateEstimatePhaseDate,
  UpdateEstimateSubTaskDate,
  UpdateEstimatePhaseAndSubTaskDate,
  UpdateEstimatePhaseOrder,
} from "@/core/models/trade-estimate";
import $axios from "../utils/axios-api-config";
import { EstimatePhaseAssignMemberStatusEnum } from "../enums/estimateEnum";
export default {
  createProjectEstimation(formData: any) {
    return $axios.post(`/project-estimate`, formData);
  },

  deleteEstimationPhaseTask(phaseTaskId: number) {
    if (phaseTaskId) {
      return $axios.delete(
        `/project-estimate/estimationPhaseTask/${phaseTaskId}`
      );
    } else {
      return null;
    }
  },

  deleteEstimationTask(estimationPhaseId: number) {
    return $axios.delete(
      `/project-estimate/estimationPhase/${estimationPhaseId}`
    );
  },
  getProjectEstimateByProjectId(projectId: number, status = false) {
    return $axios.get(`/project-estimate/${projectId}/${status}`);
  },
  getProjectEstimateByEstimationId(estimationId: number) {
    return $axios.get(`/project-estimate/details/${estimationId}`);
  },
  addProjectPhaseAttachment(
    projectEstimateId: number,
    estimationPhaseId: number,
    formData: any
  ) {
    return $axios.post(
      `/project-estimate/create-attachment/${projectEstimateId}/${estimationPhaseId}`,
      formData
    );
  },

  deleteEstimationAttachment(estimationPhaseAttachmentId: number) {
    return $axios.delete(
      `/project-estimate/estimation-phase-attachment/${estimationPhaseAttachmentId}`
    );
  },

  deleteEstimationProperty(estimatePropertyId: number) {
    return $axios.delete(
      `/project-estimate/estimation-property/${estimatePropertyId}`
    );
  },
  deleteEstimationAttachedProperty(estimateAttachedPropertyRecordId: number) {
    return $axios.delete(
      `/project-estimate/estimation-attached-property/${estimateAttachedPropertyRecordId}`
    );
  },
  updateEstimatePhaseDate(payload: UpdateEstimatePhaseDate) {
    return $axios.patch(
      `project-estimate/phase-update/${payload.estimateId}/${payload.taskId}`,
      payload.date
    );
  },
  updateEstimatePhaseSubtaskDate(payload: UpdateEstimateSubTaskDate) {
    return $axios.patch(
      `project-estimate/phase-task-update/${payload.phaseId}/${payload.taskId}`,
      payload.date
    );
  },
  updateEstimatePhaseAndSubTaskDate(
    payload: UpdateEstimatePhaseAndSubTaskDate
  ) {
    return $axios.patch(
      `/project-estimate/phase-with-task-update/${payload.estimateId}/${payload.phaseId}`,
      payload.data
    );
  },
  updateEstimatePhaseOrder(payload: UpdateEstimatePhaseOrder) {
    return $axios.patch(
      `/project-estimate/phase-update-order/${payload.estimateId}`,
      { order: payload.order }
    );
  },
  assignParticularPhaseToInvitedContractor(
    estimateId: number,
    phaseId: number,
    payload: any
  ) {
    return $axios.patch(
      `/project-estimate/phase-update/${estimateId}/${phaseId}`,
      payload
    );
  },
  depositPhaseWithEstimateUpdate(estimateId: number, payload: any) {
    return $axios.patch(
      `/project-estimate/phase-with-estimate-update/${estimateId}`,
      payload
    );
  },
  submitEstimateBySubContractor(invitationId: number) {
    return $axios.post(`/project-estimate/submit/${invitationId}`);
  },
  submitStatusWithEstimateUpdate(
    projectId: number,
    estimateId: number,
    payload: any
  ) {
    return $axios.patch(
      `/project-estimate/estimate-submit-status/${projectId}/${estimateId}`,
      payload
    );
  },
  updatePhaseStatusForSubContractor(invitationId: number, payload: any) {
    return $axios.patch(`/contractor/phase-status/${invitationId}`, payload);
  },

  updateEstimatePhase(estimateId: number, phaseId: number, payload: any) {
    return $axios.patch(
      `project-estimate/phase-update/${estimateId}/${phaseId}`,
      payload
    );
  },

  updateEstimatePhaseStatusReject(payload: any) {
    return $axios.put(`project-estimate/phase-status-reject`, payload);
  },

  acceptEstimatePhase(phaseID: any, payload: object) {
    return $axios.post(
      `project-estimate/estimate-phase/accept/${phaseID}`,
      payload
    );
  },

  rejectEstimatePhase(phaseID: any, payload: object) {
    return $axios.put(
      `project-estimate/estimate-phase/reject/${phaseID}`,
      payload
    );
  },
  rejectEstimate(estimateID: any, payload: object) {
    return $axios.put(
      `project-estimate/estimate/reject/${estimateID}`,
      payload
    );
  },
  getEstimatePhaseAttachments(
    projectEstimateId: number,
    estimationPhaseId: number
  ) {
    return $axios.get(
      `/project-estimate/get-phase-attachments/${projectEstimateId}/${estimationPhaseId}`
    );
  },
  assignEstimatePhaseToMember(payload: {
    projectEstimateId: number;
    userWorkstationMembers: number[];
    removeMemberIds: number[];
    estimatePhaseId: number;
  }) {
    return $axios.post(`/estimate_phase_assign_member`, payload);
  },
  updateAssignTeamMemberRequestStatus(
    id: number,
    payload: { status: EstimatePhaseAssignMemberStatusEnum }
  ) {
    return $axios.put(`/estimate_phase_assign_member/update/${id}`, payload);
  },
  startProjectRightAway(projectId: number) {
    return $axios.put(`/project-estimate/project-start/${projectId}`);
  },
  projectEstimateUpdate(projectId: number) {
    return $axios.put(`/project-estimate/project-estimate-update/${projectId}`);
  },
  updateVideoConsultationTime(estimateId: number, payload: { time: number }) {
    return $axios.put(
      `/project-estimate/video-consultation/set-time/${estimateId}`,
      payload
    );
  },

  deleteIBGPolicy(id: number) {
    return $axios.delete(`/project-estimate/policy/${id}`);
  },
  deleteIBGPolicyPhase(id: number) {
    return $axios.delete(`/project-estimate/policy-phase/${id}`);
  },
  insuranceBackendGuaranteeRequest(payload: any) {
    return $axios.post(`user-workstation-jobs-rates/ibg-request`, payload);
  },
  updatePolicyProperty(payload: any) {
    return $axios.put(`/project-estimate/update-policy-property`, payload);
  },
};
