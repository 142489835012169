<template>
  <div>
    <main
      class="dashboard-sec tw-pb-16 tw-box-border"
      :class="{ 'md:!tw-pb-16': isUserWorkstationIsDefault }"
    >
      <!-- Action alert -->
      <!-- This Chip for ws verified but user not verified -->
      <div
        v-if="
          activeUserWorkstation?.user?.userKYCVerificationStatus ===
            UserVerificationStatusEnum.NOT_ADDED &&
          !activeUserWorkstation?.subscription &&
          activeUserWorkstation?.subStatus !==
            UserWorkstationSubStatusEnum.SUCCEEDED &&
          !isUserWorkstationIsDefault &&
          !isWorkstationLoading
        "
        class="tw-flex tw-border tw-border-[#fa4b0080] tw-mb-5 tw-p-3 tw-rounded-xl tw-border-solid tw-gap-2 tw-items-center tw-bg-[#fa4b001a] tw-text-[#FA4B00] tw-capitalize tw-text-xs"
      >
        <img src="../../../assets/icons/alert-box-red.svg" />
        <div class="tw-capitalize tw-text-xs tw-flex-wrap tw-text-[#FA4B00]">
          Action Required: Verify your identity to get more Estimates. &nbsp;
          <span
            class="tw-underline tw-cursor-pointer tw-text-[#4B4BFF]"
            @click="onClickVerifyAccount"
            >Get Verified</span
          >
        </div>
      </div>

      <div
        v-else-if="
          userWorkStationStatus === UserVerificationStatusEnum.NOT_ADDED &&
          activeUserWorkstation?.subscription &&
          activeUserWorkstation?.subStatus ===
            UserWorkstationSubStatusEnum.SUCCEEDED &&
          !isUserWorkstationIsDefault &&
          !isWorkstationLoading
        "
        class="tw-flex tw-border tw-border-[#fa4b0080] tw-mb-5 tw-p-3 tw-rounded-xl tw-border-solid tw-gap-2 tw-items-center tw-bg-[#fa4b001a] tw-text-[#FA4B00] tw-font- tw-capitalize tw-text-xs"
      >
        <img src="../../../assets/icons/alert-box-red.svg" />
        <div class="tw-capitalize tw-text-xs tw-flex-wrap tw-text-[#FA4B00]">
          Action Required: Verify your identity to get more Estimates. &nbsp;
          <span
            class="tw-underline tw-cursor-pointer tw-text-[#4B4BFF]"
            @click="onClickVerifyAccount"
            >Get Verified</span
          >
        </div>
      </div>

      <!-- LOADER -->
      <div
        v-if="isWorkstationLoading"
        class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-full tw-w-full"
      >
        <Loader :show="isWorkstationLoading" />
      </div>

      <div v-else class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <!-- BANNER -->
        <Card
          class="!tw-max-h-[unset] tw-w-full !tw-p-5 tw-box-border"
          :withBorder="true"
          :isRoundedxl="true"
          v-if="!isUserWorkstationIsDefault"
        >
          <template #content>
            <main class="tw-w-full">
              <!-- PROFILE DETAILS BANNER -->
              <section
                v-if="!isUserWorkstationIsDefault"
                class="tw-w-full tw-flex tw-flex-col tw-items-center tw-justify-center rmd:tw-flex-row rmd:tw-justify-between tw-max-w-full md:tw-gap-5"
              >
                <div
                  class="left__block tw-flex tw-flex-col tw-gap-6 md:tw-w-full"
                >
                  <div
                    class="tw-flex tw-items-center tw-gap-5 tw-relative md:tw-w-full md:tw-max-w-full md:tw-gap-3 sm:tw-w-[auto]"
                  >
                    <ProfileThumbnail
                      :isRounded="false"
                      :showVerificationIcon="
                        userWorkStationStatus !==
                        UserVerificationStatusEnum.NOT_ADDED
                      "
                      size="xllg"
                      v-if="userWorkStationImage"
                      :src="userWorkStationImage"
                      :userKycStatus="userWorkStationStatus"
                    />
                    <div
                      v-else
                      class="tw-rounded-lg tw-max-h-[48px] tw-max-w-[48px] tw-min-h-[48px] tw-min-w-[48px] tw-h-[48px] tw-relative"
                    >
                      <ChipState
                        bgColor="#FFF"
                        :hasShadow="true"
                        class="tw-absolute tw-top-[-.3rem] tw-right-[-.4rem] tw-z-10 !tw-w-6 !tw-h-6 !tw-px-0 !tw-py-0 !tw-gap-0"
                        v-if="userWorkStationStatus != 0"
                      >
                        <template #prefix>
                          <el-tooltip effect="light" placement="bottom-start">
                            <template #content
                              ><div
                                class="tw-max-w-[200px]"
                                v-html="toolTipProfile"
                              ></div
                            ></template>
                            <CheckGreenShield
                              v-if="userWorkStationStatus == 2"
                            />
                            <InfoIconRed v-else :color="`#faa500`" />
                          </el-tooltip>
                        </template>
                      </ChipState>

                      <UserProfileLogo
                        :isRounded="false"
                        :desktopFontSizeBig="true"
                      />
                    </div>
                    <!-- <div
                      class="verified__icon tw-absolute tw-left-[90px] sm:!tw-left-[33px] tw-top-0 tw-w-[20px] tw-h-[20px] tw-bg-white tw-rounded tw-flex tw-items-center tw-justify-center"
                      v-if="
                        userWorkStationStatus ===
                        UserVerificationStatusEnum.VERIFIED
                      "
                    >
                      <img src="../../../assets/icons/verified.svg" alt="" />
                    </div> -->
                    <div
                      class="tw-flex tw-flex-col tw-items-start tw-max-w-full"
                    >
                      <h5
                        class="tw-text-[22px] tw-text-[#0C0F4A] tw-font-medium tw-leading-8 tw-text md:tw-text-lg"
                      >
                        {{ activeUserWorkstation?.name }}
                      </h5>
                      <div
                        v-if="
                          activeUserWorkstation?.user?.userBusinessDetails
                            ?.residencyAddressLine
                        "
                        class="tw-flex tw-items-center tw-gap-0 rmd:tw-gap-1 tw-flex-row-reverse sm:tw-flex-wrap sm:tw-flex-col-reverse sm:tw-items-start"
                      >
                        <Text
                          class="tw-flex tw-gap-1 !tw-text-[10px] tw-text-[#0C0F4A80]"
                          textWeight="400"
                          textColor="#0C0F4A"
                          whiteSpace="nowrap"
                          ><img src="@/assets/icons/map.svg" />{{
                            getWorkstationPostcode(
                              user?.userBusinessDetails?.type
                            )
                          }}</Text
                        >
                        <Text
                          class="!tw-text-[10px] tw-text-[#0C0F4A80]"
                          textWeight="400"
                          textColor="#0C0F4A"
                          whiteSpace="nowrap"
                          >{{
                            getWorkstationAddress(
                              user?.userBusinessDetails?.type
                            )
                          }}</Text
                        >
                      </div>
                      <div
                        @click="onClickAddress"
                        class="tw-flex tw-gap-1 tw-items-center tw-cursor-pointer"
                      >
                        <Text
                          :textColor="
                            activeUserWorkstation?.user?.userBusinessDetails
                              ?.residencyAddressLine
                              ? '#5C4EFF'
                              : '#FFA500'
                          "
                          variant="p"
                          >+</Text
                        >
                        <Text
                          :textColor="
                            activeUserWorkstation?.user?.userBusinessDetails
                              ?.residencyAddressLine
                              ? '#5C4EFF'
                              : '#FFA500'
                          "
                          variant="span"
                        >
                          {{
                            activeUserWorkstation?.user?.userBusinessDetails
                              ?.residencyAddressLine
                              ? "Edit Address"
                              : "Add Address"
                          }}
                        </Text>
                      </div>
                    </div>
                  </div>
                  <div
                    class="view-rating-box md:!tw-gap-[4px] !tw-justify-between md:!tw-max-w-[unset] md:!tw-w-full md:!tw-p-[.5rem]"
                  >
                    <div
                      class="tw-flex tw-items-center tw-gap-[8px] md:!tw-gap-[4px]"
                    >
                      <v-rating
                        :model-value="getAverageOfAllProjectReview"
                        active-color="orange"
                        color="rgba(12, 15, 74, 0.26)"
                        empty-icon="mdi-star"
                        readonly
                      ></v-rating>
                      <p class="md:!tw-text-[12px]">
                        {{ getAverageOfAllProjectReview }} out of 5
                      </p>
                    </div>
                    <v-btn
                      @click="onClickGotoRatingPage"
                      class="button button-orange"
                      append-icon="mdi-chevron-right"
                      >view Ratings
                      <!-- <template v-slot:append>
                        <v-icon
                          color="white"
                          icon="mdi-chevron-right"
                        ></v-icon> </template
                    > -->
                    </v-btn>
                  </div>
                </div>

                <div
                  class="tw-flex tw-flex-col tw-justify-between tw-items-end tw-relative md:tw-w-full md:tw-max-w-full tw-min-h-[180px] md:tw-min-h-[auto] md:tw-gap-4"
                >
                  <!-- <div
                    v-if="activeUserWorkstation?.subscription"
                    class="free_workstation_box tw-flex tw-items-center tw-gap-1 tw-px-4 tw-py-1 tw-rounded-lg"
                  >
                    <img src="../../../assets/icons/red-exclaimation.svg" />

                    <span
                      class="tw-text-xs tw-font-bold tw-leading-6 tw-text-[#FA4B00] tw-tracking-wide"
                      >{{ userWorkstationStatusByWord }}</span
                    >
                  </div> -->

                  <ProfileVerificationStatusChip
                    v-if="
                      // activeUserWorkstation?.verificationStatus !==
                      //   UserVerificationStatusEnum.VERIFIED &&
                      activeUserWorkstation?.subscription &&
                      activeUserWorkstation?.subStatus ===
                        UserWorkstationSubStatusEnum.SUCCEEDED &&
                      userWorkStationStatus ===
                        UserVerificationStatusEnum.NOT_ADDED
                    "
                    class="tw-hidden rmd:tw-flex"
                    :variant="userWorkstationStatusByWord"
                  />

                  <!-- for display free workstation -->
                  <div
                    class="tw-flex tw-items-end tw-gap-1 tw-flex-col md:tw-flex-row md:tw-justify-between tw-w-full tw-max-w-full"
                    v-if="
                      userWorkStationStatus ===
                        UserVerificationStatusEnum.NOT_ADDED &&
                      (!activeUserWorkstation?.subscription ||
                        activeUserWorkstation?.subStatus !==
                          UserWorkstationSubStatusEnum.SUCCEEDED)
                    "
                  >
                    <div
                      class="free_workstation_box tw-flex tw-items-center tw-gap-1 tw-px-4 tw-py-1 tw-rounded-lg"
                    >
                      <img src="../../../assets/icons/green-star-circle.svg" />

                      <span
                        class="tw-text-xs tw-font-bold tw-leading-6 tw-text-[#24BD63] tw-tracking-wide tw-whitespace-nowrap"
                        >FREE WORKSTATION</span
                      >
                    </div>
                    <v-btn
                      class="upgrade_btn tw-text-[10px] tw-font-bold tw-leading-6 tw-tracking-[1.25px] tw-opacity-100 tw-capitalize tw-p-0"
                      variant="plain"
                      append-icon="mdi-chevron-right"
                      @click="onClickUpgradePlan"
                      >Upgrade Plan</v-btn
                    >
                  </div>
                  <v-btn
                    class="button button-purple w-full tw-mt-auto"
                    @click="onClickAddNewProperty"
                  >
                    add a new property
                  </v-btn>
                </div>
              </section>
              <!-- CONTROL CENTER BANNER -->
              <!-- WELCOME BANNER -->
              <!-- <section
                v-else
                class="tw-w-full tw-flex tw-flex-col tw-gap-2 rmd:tw-flex-row rmd:tw-items-center rmd:tw-justify-between"
              >
                <div
                  class="tw-flex tw-flex-col tw-gap-4 tw-items-start md:tw-gap-2"
                >
                  <Text
                    class="rlg:!tw-text-[28px]"
                    variant="h6"
                    textWeight="400"
                    >Welcome to your iknowa HQ,
                    <Text class="rlg:!tw-text-[28px]" variant="h6">{{
                      userName
                    }}</Text></Text
                  >
                  <Text
                    class="rlg:!tw-text-sm"
                    variant="span"
                    textWeight="400"
                    textColor="rgba(12, 15, 74, 0.5)"
                    >This is your personal profile. Create a workstation to get
                    started with the best suited option for you.</Text
                  >
                </div>
                <v-btn
                  class="button button-purple"
                  @click="onClickToggleTradesWorkStation"
                  >CREATE A PROPERTY WORKSTATION
                </v-btn>
              </section> -->
            </main>
          </template>
        </Card>

        <!-- PROPERTY OWNER DASHBOARD -->
        <!-- v-if="
          !isUserWorkstationIsDefault && activeUserWorkstation && !initialiazing
        " -->

        <section
          v-if="!isUserWorkstationIsDefault"
          class="workstation-dashboard tw-flex tw-flex-col tw-gap-6 tw-w-full tw-h-full"
        >
          <!-- WORKSTATION DETAILS -->
          <div class="tw-relative">
            <!-- <WorkStationVerificationLock
            v-if="
              userWorkStationStatus ===
              UserVerificationStatusEnum.PENDING_VERIFICATION
            "
          /> -->
          </div>
          <div
            class="tw-w-full tw-h-full tw-grid tw-grid-cols-1 tw-gap-6 tw-box-border rlg:tw-grid-cols-2"
          >
            <div
              class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-6 tw-min-h-[340px]"
            >
              <PropertiesWidget />
            </div>
            <div
              class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-6 tw-min-h-full"
            >
              <PreferredContractorsWidget />
            </div>
          </div>
          <div
            class="tw-w-full tw-h-full tw-grid tw-grid-cols-1 tw-gap-6 tw-box-border rlg:tw-grid-cols-2"
          >
            <div class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-6">
              <ProjectsOverviewWidget />
              <TeamWidget
                className="!tw-min-h-[275px]"
                :propMembers="members"
                @get-members="getTeamMember"
              />
            </div>
            <div class="tw-w-full tw-h-full tw-flex tw-flex-col tw-gap-6">
              <CalendarEventWidget className="property_calendar" />
            </div>
          </div>
        </section>

        <!-- CONTROL CENTER -->

        <section
          v-else
          class="tw-grid rlg:tw-grid-cols-2 tw-gap-4 rlg:!tw-gap-6 tw-max-w-[1332px] rlg:tw-mt-4"
        >
          <!-- CREATE WORKSTATION BANNER -->
          <Text
            variant="h4"
            class="tw-mb-4 tw-capitalize rmd:!tw-text-[32px] md:!tw-mb-0 rlg:tw-col-span-2"
            >Hello, {{ user?.firstName }}</Text
          >

          <Card
            class="manage-ws-card !tw-max-h-[unset] tw-w-full tw-box-border tw-p-6 !tw-px-8 !tw-py-6 md:!tw-px-4 rlg:tw-col-span-2"
            :withBorder="true"
            bgColor="rgba(241, 246, 251, 0.50)"
            v-if="
              !filteredUserAllWorkStation.length &&
              !WorkStationInvitation.length
            "
          >
            <template #content>
              <main
                class="tw-w-full tw-flex tw-flex-col tw-justify-between tw-h-full"
              >
                <section
                  class="custom-card tw-box-border with-border !tw-rounded-[16px] manage-ws-card !tw-max-h-[unset] tw-w-full tw-flex tw-items-center tw-gap-6 lg:!tw-flex-col"
                >
                  <div
                    class="tw-w-full tw-flex tw-flex-col tw-justify-between tw-h-full"
                  >
                    <div
                      class="tw-w-full tw-flex tw-items-center tw-gap-6 lg:!tw-flex-col"
                    >
                      <div
                        class="tw-flex tw-items-start tw-flex-col tw-gap-6 lg:!tw-w-full"
                      >
                        <Text
                          variant="h3"
                          class="md:!tw-text-[20px]"
                          whiteSpace="nowrap"
                          >Getting started with iknowa</Text
                        >
                        <div
                          class="tw-flex tw-items-center tw-gap-6 lg:!tw-flex-col lg:!tw-w-full lg:!tw-items-start"
                        >
                          <div
                            class="tw-min-w-[292px] tw-w-full sign-up-empty-state-banner tw-px-6 tw-py-4 tw-box-border tw-flex tw-flex-col tw-items-start tw-gap-2 tw-bg-[rgba(197,210,242,0.24)] tw-rounded-lg"
                          >
                            <div class="tw-flex tw-items-center tw-gap-2">
                              <v-icon
                                icon="mdi-check-circle"
                                color="#264FD5"
                              ></v-icon>
                              <Text variant="span" textColor="#264FD5"
                                >Completed</Text
                              >
                            </div>
                            <Text variant="h5" whiteSpace="nowrap"
                              >Sign up to iknowa</Text
                            >
                            <Text variant="h6" textWeight="400"
                              >You’ve just signed up to<br />
                              iknowa via a link from NWG.</Text
                            >
                          </div>
                          <img
                            class="tw-hidden rlg:!tw-flex"
                            :src="
                              require('@/assets/images/up-next-desktop.svg')
                            "
                            alt=""
                          />
                          <img
                            class="tw-flex rlg:!tw-hidden"
                            :src="require('@/assets/images/up-next-mobile.svg')"
                            alt=""
                          />
                        </div>
                      </div>
                      <div
                        class="tw-w-full tw-flex-col tw-bg-white tw-border-solid-default tw-px-8 tw-box-border tw-py-6 tw-flex tw-items-start tw-gap-6 tw-rounded-[16px] rxl:!tw-flex-row"
                      >
                        <img
                          class="tw-w-[200px]"
                          :src="require('@/assets/images/tp-banner.svg')"
                          alt="iknowa banner"
                        />
                        <div
                          class="tw-flex tw-flex-col tw-gap-4 tw-items-start"
                        >
                          <Text variant="h5"
                            >Create your free property workstation</Text
                          >
                          <Text variant="h6" textWeight="400"
                            >List properties, raise property service projects,
                            and seamlessly collaborate.</Text
                          >
                          <Button
                            class="!tw-w-auto"
                            :label="
                              filteredUserAllWorkStation &&
                              filteredUserAllWorkStation.lenght
                                ? 'CREATE A new WORKSTATION'
                                : 'CREATE A free WORKSTATION'
                            "
                            variant="accent"
                            @click="onClickToggleTradesWorkStation"
                          ></Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </main>
            </template>
          </Card>

          <!-- MANAGE WORKSTATIONS -->
          <Card
            :withBorder="true"
            :isRoundedxl="true"
            bgColor="rgba(241, 246, 251, 0.50)"
            v-if="
              (filteredUserAllWorkStation &&
                filteredUserAllWorkStation.length) ||
              WorkStationInvitation.length
            "
            class="!tw-max-h-[unset] tw-w-full tw-box-border tw-p-6 md:tw-p-4 rlg:tw-col-span-2"
          >
            <template #content>
              <main
                class="tw-w-full tw-flex tw-flex-col tw-justify-between tw-h-full"
              >
                <section
                  class="tw-w-full tw-flex tw-items-center tw-justify-between tw-pb-4 tw-box-border"
                >
                  <div
                    class="tw-flex tw-items-center tw-gap-4 md:!tw-flex-col md:!tw-items-start md:!tw-gap-[.5rem] md:!tw-w-full"
                  >
                    <Text class="!tw-text-[20px] rlg:!tw-text-[32px]"
                      >Launch a Workstation</Text
                    >

                    <!-- <InputSelect
                      class="tw-max-w-full !tw-w-[300px] md:!tw-w-full"
                      placeholder="Select an email"
                      density="compact"
                      :items="[
                        'sampleemail@gmail.com',
                        'sampleemail2@gmail.com',
                      ]"
                      variant="outlined"
                    /> -->
                  </div>
                </section>
                <!-- WORKSTATION FILLED STATE -->
                <section
                  class="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-pt-5 tw-box-border tw-overflow-auto no-scrollbar"
                  v-if="
                    filteredUserAllWorkStation.length > 0 ||
                    WorkStationInvitation.length > 0
                  "
                >
                  <!-- Workstation invitation -->
                  <div
                    v-if="
                      WorkStationInvitation && WorkStationInvitation.length > 0
                    "
                    class="tw-flex tw-flex-col tw-gap-4 tw-border-b tw-border-0 tw-border-dashed tw-border-[#C5D2F2] tw-pb-5"
                  >
                    <div
                      class="tw-justify-between tw-flex-col ws-items-bg tw-w-full tw-flex tw-items-start tw-p-4 tw-rounded-[6px]"
                      v-for="(member, index) in WorkStationInvitation"
                      :key="index"
                    >
                      <div
                        class="tw-flex tw-items-center tw-w-full tw-justify-between md:tw-border-0 md:tw-pb-3 md:tw-border-b md:tw-border-dashed md:tw-border-[#8687A5] s430:tw-gap-2"
                      >
                        <div
                          class="chip-state tw-px-[.5rem] tw-py-[.2rem] tw-text-[14px] tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F98228]/[0.26] tw-text-[#0C0F4A] tw-font-medium tw-capitalize"
                        >
                          {{
                            member?.userRole?.id === 2
                              ? "Property owner"
                              : member?.userRole?.id === 3
                              ? "Sole trader"
                              : ""
                          }}
                        </div>
                      </div>
                      <div
                        class="tw-w-full tw-flex tw-items-center md:tw-items-start tw-gap-2 rmd:!tw-items-start tw-mt-3"
                      >
                        <!-- profile -->
                        <img
                          class="ws-profile-logo tw-rounded-full tw-overflow-hidden tw-w-[56px] tw-min-w-[56px] tw-h-[56px] tw-object-cover tw-relative"
                          v-if="InviteMemberImage"
                          :src="InviteMemberImage"
                        />
                        <WorkStationProfileLogo
                          class="tw-rounded-full tw-overflow-hidden tw-w-[56px] tw-min-w-[56px] tw-h-[56px] tw-relative"
                          v-else
                          :WSName="member?.userWorkstation?.name"
                        />
                        <!-- details -->
                        <div
                          class="tw-w-full tw-flex md:tw-flex-col md:tw-items-start tw-justify-between tw-items-center tw-gap-2 tw-flex-wrap"
                        >
                          <div class="tw-flex tw-flex-col tw-item-start">
                            <div
                              class="tw-flex tw-items-center md:tw-flex-col-reverse md:tw-items-start tw-gap-2 tw-flex-wrap"
                            >
                              <Text variant="h6">{{
                                member?.userWorkstation?.name
                              }}</Text>
                              <div
                                class="chip-state tw-px-[.5rem] tw-py-[.2rem] tw-text-[14px] tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F98228]/[0.26] tw-text-[#0C0F4A] tw-font-medium tw-capitalize"
                              >
                                {{ member?.role }}
                              </div>

                              <!-- <SignInChip /> -->
                            </div>
                            <div class="tw-flex tw-flex-wrap tw-gap-2 tw-mt-2">
                              <div
                                v-for="tag in parseJSON(member?.tags)"
                                :key="tag"
                              >
                                <div
                                  class="tw-px-[.5rem] tw-py-[.2rem] tw-box-border tw-text-[14px] tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#0c0f4a1a] tw-text-[#0C0F4A] tw-font-medium tw-capitalize"
                                >
                                  {{ tag }}
                                </div>
                              </div>
                            </div>
                            <div class="tw-flex tw-items-start tw-flex-col">
                              <div
                                class="tw-flex tw-items-start tw-flex-col tw-gap-2"
                              >
                                <Text
                                  variant="span"
                                  textWeight="600"
                                  textColor="rgba(12, 15, 74, 0.5)"
                                  >{{ member?.user?.email }}</Text
                                >
                                <!-- <Text
                          variant="span"
                          textWeight="600"
                          textColor="#4B4BFF"
                          >+2 more</Text
                        > -->
                              </div>
                              <Text
                                v-if="member?.message"
                                variant="span"
                                textWeight="600"
                                textColor="rgba(12, 15, 74, 0.5)"
                                >{{ member?.message }}</Text
                              >

                              <Text
                                class="!tw-text-[10px] md:!tw-text-[11px] !tw-leading-none !tw-text-darkBlue/50"
                                textWeight="400"
                                >Last signed in:
                                {{
                                  member?.lastLoginAt
                                    ? formatDate(
                                        member?.lastLoginAt,
                                        "fullDate"
                                      )
                                    : "Not Signed"
                                }}</Text
                              >
                            </div>
                          </div>

                          <div
                            class="tw-flex tw-items-center tw-gap-2 tw-flex-wrap"
                            v-if="
                              member?.invitationStatus ===
                              MemberInvitationStatusEnum.PENDING
                            "
                          >
                            <v-btn
                              class="tw-px-[8px] tw-py-[6px] md:tw-py-[10px] tw-box-border tw-rounded-[6px] tw-text-[12px] tw-flex tw-gap-3 tw-items-center tw-justify-center tw-bg-[#F45454] tw-text-white tw-font-bold tw-capitalize !tw-h-[auto] tw-leading-none tw-shadow-none"
                              @click="onClickRejectWSInvitation(member?.id)"
                            >
                              <InfoIconWhite />
                              <span class="tw-ml-[3px]">Reject</span>
                            </v-btn>
                            <v-btn
                              :loading="
                                acceptInvitaionLoader &&
                                acceptInvitationWorkstationId === member?.token
                              "
                              class="tw-px-[8px] tw-py-[6px] md:tw-py-[10px] tw-box-border tw-rounded-[6px] tw-text-[12px] tw-flex tw-gap-3 tw-items-center tw-justify-center tw-bg-[#1FB27A] tw-text-white tw-font-bold tw-capitalize !tw-h-[auto] tw-leading-none tw-shadow-none"
                              @click="onClickAcceptWSInvitation(member?.token)"
                            >
                              <CheckIconWhite />
                              <span class="tw-ml-[3px]">Accept</span>
                            </v-btn>
                            <!-- <AcceptChip /> -->
                            <!-- <MyWorkstationChip /> -->
                          </div>
                          <div
                            class="tw-flex tw-items-center tw-gap-2"
                            v-if="
                              member?.invitationStatus ===
                              MemberInvitationStatusEnum.ACCEPTED
                            "
                          >
                            <v-btn
                              class="tw-px-4 tw-py-2 tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#4B4BFF] tw-text-white tw-font-medium tw-capitalize tw-text-sm !tw-h-[auto] tw-leading-5"
                              @click="
                                onWorkStationClick(member?.userWorkstation?.id)
                              "
                            >
                              launch <LunchIcon class="tw-ml-2" />
                            </v-btn>
                            <v-btn
                              class="tw-px-4 tw-py-2 tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F45454] tw-text-white tw-font-medium tw-capitalize tw-text-sm !tw-h-[auto] tw-leading-5"
                              @click="onClickRejectWSInvitation(member?.id)"
                            >
                              Delete
                              <TrashIcon
                                class="tw-ml-2"
                                fill="#FFF"
                                fillOpacity="1"
                              />
                            </v-btn>
                            <div class="tw-flex tw-items-center tw-gap-2">
                              <DeleteActionChip
                                @click="onClickRejectWSInvitation(member?.id)"
                              />
                            </div>
                            <!-- <MyWorkstationChip /> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- User Workstation -->
                  <div
                    class="tw-w-full tw-flex tw-flex-col tw-gap-3 tw-justify-between"
                  >
                    <template
                      v-for="(member, index) in filteredUserAllWorkStation"
                    >
                      <div
                        class="tw-justify-between tw-flex-col ws-items-bg tw-w-full tw-flex tw-items-start tw-p-4 tw-rounded-[6px]"
                        v-if="index < numberOfWsShowed"
                        :key="index"
                      >
                        <div
                          class="tw-flex tw-items-center tw-w-full tw-justify-between md:tw-border-0 md:tw-pb-3 md:tw-border-b md:tw-border-dashed md:tw-border-[#8687A5] s430:tw-gap-2"
                        >
                          <div
                            class="chip-state tw-px-[.5rem] tw-py-[.2rem] tw-text-[14px] tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F98228]/[0.26] tw-text-[#0C0F4A] tw-font-medium tw-capitalize"
                          >
                            {{
                              member?.userRole?.id === 2
                                ? "Property owner"
                                : member?.userRole?.id === 3
                                ? "Sole trader"
                                : ""
                            }}
                          </div>
                          <div
                            class="md:tw-flex tw-items-center tw-gap-2 tw-flex-wrap tw-relative tw-hidden"
                          >
                            <NotificationIcon />
                            <span
                              class="notification__count tw-absolute tw-top-0 tw-left-8 tw-w-[15px] tw-h-[15px] tw-bg-red-400 tw-text-white tw-rounded-full tw-text-center tw-text-xs"
                            >
                              {{
                                workStationNotificationCount[member?.id] || 0
                              }}</span
                            >
                            <v-btn
                              class="tw-px-4 md:tw-px-2 tw-py-2 tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#4B4BFF] tw-text-white tw-font-medium tw-capitalize tw-text-sm md:tw-text-[11px] !tw-h-[auto] tw-leading-5"
                              @click="onWorkStationClick(member?.id)"
                            >
                              launch
                              <LunchIcon class="tw-ml-2 md:tw-max-w-[9px]" />
                            </v-btn>
                            <v-btn
                              :loading="
                                deleteInvitedWorkstationLoader &&
                                deleteInvitedWorkstationId === member?.memberId
                              "
                              v-if="member?.isInvited"
                              class="tw-px-4 tw-py-2 tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F45454] tw-text-white tw-font-medium tw-capitalize tw-text-sm !tw-h-[auto] tw-leading-5 md:tw-hidden"
                              @click="
                                onClickRejectWSInvitation(member?.memberId)
                              "
                            >
                              Delete
                              <TrashIcon
                                class="tw-ml-2"
                                fill="#FFF"
                                fillOpacity="1"
                              />
                            </v-btn>
                            <!-- <AcceptChip /> -->
                            <!-- <MyWorkstationChip /> -->
                          </div>
                        </div>

                        <div
                          class="tw-w-full tw-flex tw-items-center md:tw-items-start tw-gap-2 rmd:!tw-items-start tw-mt-3"
                        >
                          <!-- profile -->
                          <img
                            v-if="member?.imageUrl"
                            :src="member?.imageUrl"
                            class="ws-profile-logo tw-rounded-full tw-overflow-hidden tw-w-[56px] tw-min-w-[56px] tw-h-[56px] tw-object-cover tw-relative"
                          />
                          <WorkStationProfileLogo
                            class="tw-rounded-full tw-overflow-hidden tw-w-[56px] tw-min-w-[56px] tw-h-[56px] tw-relative"
                            v-else
                            :WSName="member?.name"
                          />
                          <!-- details -->
                          <div
                            class="tw-flex tw-justify-between tw-items-center tw-gap-2 tw-flex-wrap tw-w-[calc(100%_-_64px)] md:!tw-flex-col md:!tw-items-start"
                          >
                            <div
                              class="tw-flex tw-flex-col tw-item-start tw-gap-[2px]"
                            >
                              <div
                                class="tw-flex tw-items-center md:tw-flex-col-reverse md:tw-items-start tw-gap-2 tw-flex-wrap"
                              >
                                <Text variant="h6">{{ member?.name }}</Text>
                                <div
                                  v-if="member?.role"
                                  class="chip-state tw-px-[.5rem] tw-text-[14px] tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F98228]/[0.26] tw-text-[#0C0F4A] tw-font-medium tw-capitalize !tw-py-0"
                                >
                                  <Text variant="p" textWeight="400">{{
                                    member?.role
                                  }}</Text>
                                </div>
                                <div
                                  v-else
                                  class="chip-state tw-px-[.5rem] tw-text-[14px] tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F98228]/[0.26] tw-text-[#0C0F4A] tw-font-medium tw-capitalize !tw-py-0"
                                >
                                  <Text variant="p" textWeight="400"
                                    >Admin</Text
                                  >
                                </div>

                                <!-- <SignInChip /> -->
                              </div>
                              <div
                                v-if="member?.tags"
                                class="tw-flex tw-gap-2 tw-flex-wrap tw-mt-1"
                              >
                                <div
                                  class="tw-flex"
                                  v-for="tag in parseJSON(member?.tags)"
                                  :key="tag"
                                >
                                  <div
                                    class="tw-px-[.5rem] tw-py-[.2rem] tw-box-border tw-text-[14px] tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#0c0f4a1a] tw-text-[#0C0F4A] tw-font-medium tw-capitalize"
                                  >
                                    {{ tag }}
                                  </div>
                                </div>
                              </div>
                              <div
                                class="tw-h-full tw-flex tw-items-start tw-flex-col tw-justify-between tw-gap-[3px]"
                              >
                                <div class="tw-flex tw-items-center">
                                  <!-- <div
                                    v-for="items in 6"
                                    :key="items"
                                    class="tw-mr-[-3px] tw-rounded-[2px] tw-border-white tw-border-[1px] tw-border tw-border-solid tw-min-w-[16px] tw-w-[16px] tw-h-[16px] tw-overflow-hidden tw-flex tw-items-center tw-justify-center"
                                  >
                                    <img
                                      class="tw-w-full tw-h-full tw-object-cover tw-rounded-[2px]"
                                      :src="
                                        require('@/assets/images/sample-avatar-2.png')
                                      "
                                      alt="iknowa banner"
                                    />
                                  </div>
                                  <div
                                    class="tw-border tw-border-[rgba(255,255,255,1)] tw-border-[0.5px] tw-mr-[-3px] tw-rounded-[4px] tw-min-w-[16px] tw-w-[16px] tw-h-[16px] tw-overflow-hidden tw-flex tw-items-center tw-justify-center tw-bg-white"
                                  >
                                    <Text
                                      variant="xsmall"
                                      textColor="rgba(12, 15, 74, 0.5)"
                                      >+4</Text
                                    >
                                  </div> -->
                                  <!-- <Text
                                  variant="span"
                                  textWeight="600"
                                  textColor="rgba(12, 15, 74, 0.5)"
                                  >{{
                                    processedEmail(member?.user?.email)
                                  }}</Text
                                > -->
                                </div>
                                <Text
                                  v-if="member?.message"
                                  variant="span"
                                  textWeight="600"
                                  textColor="rgba(12, 15, 74, 0.5)"
                                  >{{ member?.message }}</Text
                                >

                                <Text
                                  class="!tw-text-[10px] md:!tw-text-[11px] !tw-leading-none !tw-text-darkBlue/50"
                                  textWeight="400"
                                  >Last signed in:
                                  {{
                                    member?.lastLoginAt
                                      ? formatDate(
                                          member?.lastLoginAt,
                                          "fullDate"
                                        )
                                      : "Not Signed"
                                  }}</Text
                                >
                              </div>
                            </div>

                            <div
                              class="tw-flex tw-items-center tw-gap-2 tw-flex-wrap tw-relative md:tw-hidden"
                            >
                              <NotificationIcon />
                              <span
                                class="notification__count tw-absolute tw-top-0 tw-left-8 tw-w-[15px] tw-h-[15px] tw-bg-red-400 tw-text-white tw-rounded-full tw-text-center tw-text-xs"
                              >
                                {{
                                  workStationNotificationCount[member?.id] || 0
                                }}</span
                              >
                              <v-btn
                                class="tw-px-4 tw-py-2 tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#4B4BFF] tw-text-white tw-font-medium tw-capitalize tw-text-sm !tw-h-[auto] tw-leading-5"
                                @click="onWorkStationClick(member?.id)"
                              >
                                launch <LunchIcon class="tw-ml-2" />
                              </v-btn>
                              <v-btn
                                :loading="
                                  deleteInvitedWorkstationLoader &&
                                  deleteInvitedWorkstationId ===
                                    member?.memberId
                                "
                                v-if="member?.isInvited"
                                class="tw-px-4 tw-py-2 tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F45454] tw-text-white tw-font-medium tw-capitalize tw-text-sm !tw-h-[auto] tw-leading-5"
                                @click="
                                  onClickRejectWSInvitation(member?.memberId)
                                "
                              >
                                Delete
                                <TrashIcon
                                  class="tw-ml-2"
                                  fill="#FFF"
                                  fillOpacity="1"
                                />
                              </v-btn>
                              <!-- <AcceptChip /> -->
                              <!-- <MyWorkstationChip /> -->
                            </div>
                          </div>
                        </div>
                        <div
                          class="md:tw-mt-3 md:tw-block tw-hidden tw-ml-auto"
                        >
                          <v-btn
                            :loading="
                              deleteInvitedWorkstationLoader &&
                              deleteInvitedWorkstationId === member?.memberId
                            "
                            v-if="member?.isInvited"
                            class="tw-px-4 tw-py-2 tw-box-border tw-rounded tw-flex tw-gap-2 tw-items-center tw-justify-center tw-bg-[#F45454] tw-text-white tw-font-medium tw-capitalize tw-text-sm !tw-h-[auto] tw-leading-5"
                            @click="onClickRejectWSInvitation(member?.memberId)"
                          >
                            Delete
                            <TrashIcon
                              class="tw-ml-2"
                              fill="#FFF"
                              fillOpacity="1"
                            />
                          </v-btn>
                        </div>
                      </div>
                    </template>
                  </div>

                  <!-- Show more -->

                  <div
                    class="tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-2 tw-pt-5 tw-border-t-solid-custom s540:!tw-flex-col s540:!tw-items-start"
                  >
                    <div
                      class="tw-flex tw-items-center"
                      v-if="filteredUserAllWorkStation?.length > 3"
                    >
                      <div
                        class="tw-flex tw-items-center tw-gap-2 tw-cursor-pointer"
                        @click="toggleShowWs"
                        v-if="!isShowAllws"
                      >
                        <Text
                          textWeight="600"
                          variant="h6"
                          textColor="rgba(12, 15, 74, 0.5)"
                          >See
                          {{ `${filteredUserAllWorkStation?.length - 3}` }} more
                          workstation</Text
                        >
                        <v-icon
                          icon="mdi-chevron-down"
                          color="rgba(12, 15, 74, 0.5)"
                        ></v-icon>
                      </div>
                      <div
                        class="tw-flex tw-items-center tw-gap-2 tw-cursor-pointer"
                        @click="toggleShowWs"
                        v-if="isShowAllws"
                      >
                        <Text
                          textWeight="600"
                          variant="p"
                          textColor="rgba(12, 15, 74, 0.5)"
                          >See less workstation</Text
                        >
                        <v-icon
                          icon="mdi-chevron-up"
                          color="rgba(12, 15, 74, 0.5)"
                        ></v-icon>
                      </div>
                    </div>
                    <Button
                      class="!tw-w-auto !tw-p-0 tw-box-border !tw-text-[#FFA500]"
                      variant="custom"
                      @click="onClickToggleTradesWorkStation"
                      label="Create a new workstation"
                      activeBgColor="transparent"
                      width="auto"
                      :isCapitalize="true"
                      :fontWeight="'700'"
                    >
                      <template #postfix>
                        <v-icon
                          icon="mdi-chevron-right"
                          color="#FFA500"
                        ></v-icon>
                      </template>
                    </Button>
                  </div>
                </section>
                <!-- WORKSTATION EMPTY STATE -->

                <section
                  v-else
                  class="custom-card tw-box-border with-border !tw-rounded-[16px] manage-ws-card !tw-max-h-[unset] tw-w-full tw-flex tw-items-center tw-gap-6 lg:!tw-flex-col"
                >
                  <div
                    class="tw-flex tw-items-start tw-flex-col tw-gap-6 lg:!tw-w-full"
                  >
                    <Text
                      variant="h3"
                      class="md:!tw-text-[20px]"
                      whiteSpace="nowrap"
                      >Getting started with iknowa</Text
                    >
                    <div
                      class="tw-flex tw-items-center tw-gap-6 lg:!tw-flex-col lg:!tw-w-full lg:!tw-items-start"
                    >
                      <div
                        class="tw-min-w-[292px] tw-w-full sign-up-empty-state-banner tw-px-6 tw-py-4 tw-box-border tw-flex tw-flex-col tw-items-start tw-gap-2 tw-bg-[rgba(197,210,242,0.24)] tw-rounded-lg"
                      >
                        <div class="tw-flex tw-items-center tw-gap-2">
                          <v-icon
                            icon="mdi-check-circle"
                            color="#264FD5"
                          ></v-icon>
                          <Text variant="span" textColor="#264FD5"
                            >Completed</Text
                          >
                        </div>
                        <Text variant="h5" whiteSpace="nowrap"
                          >Sign up to iknowa</Text
                        >
                        <Text variant="h6" textWeight="400"
                          >You’ve just signed up to<br />
                          iknowa via a link from NWG.</Text
                        >
                      </div>
                      <img
                        class="tw-hidden rlg:!tw-flex"
                        :src="require('@/assets/images/up-next-desktop.svg')"
                        alt=""
                      />
                      <img
                        class="tw-flex rlg:!tw-hidden"
                        :src="require('@/assets/images/up-next-mobile.svg')"
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    class="tw-w-full tw-flex-col tw-bg-white tw-border-solid-default tw-px-8 tw-box-border tw-py-6 tw-flex tw-items-start tw-gap-6 tw-rounded-[16px] rxl:!tw-flex-row"
                  >
                    <img
                      class="tw-w-[200px]"
                      :src="require('@/assets/images/tp-banner.svg')"
                      alt="iknowa banner"
                    />
                    <div class="tw-flex tw-flex-col tw-gap-4 tw-items-start">
                      <Text variant="h5"
                        >Create your free property workstation</Text
                      >
                      <Text variant="h6" textWeight="400"
                        >Unlock access to subsidised upskilling courses,
                        estimate new job opportunities, and seamlessly
                        collaborate.</Text
                      >
                      <Button
                        class="!tw-w-auto"
                        :label="
                          filteredUserAllWorkStation &&
                          filteredUserAllWorkStation.lenght
                            ? 'CREATE A new WORKSTATION'
                            : 'CREATE A Free WORKSTATION'
                        "
                        variant="accent"
                        @click="onClickToggleTradesWorkStation"
                      ></Button>
                    </div>
                  </div>
                </section>
              </main>
            </template>
          </Card>

          <!-- CAN'T SEE WS -->
          <!-- <Card
            :withBorder="true"
            :isRoundedxl="true"
            v-if="
              filteredUserAllWorkStation && filteredUserAllWorkStation.length
            "
            class="!tw-rounded-[16px] tw-p-6 !tw-box-border"
          >
            <template #content>
              <div
                class="tw-flex tw-flex-col tw-gap-4 tw-items-start rlg:!tw-flex-row rlg:!tw-flex-row-between"
              >
                <Text
                  class="!tw-text-[20px] rlg:!tw-text-[32px] rlg:!tw-leading-[28px]"
                  lineHeight="26px"
                  >Can’t see your workstation?</Text
                >
                <div
                  class="tw-flex tw-items-center tw-gap-4 md:!tw-flex-col md:!tw-items-start md:!tw-w-full md:!tw-gap-[.5rem]"
                >
                  <Text
                    class="!tw-text-[16px] rlg:!tw-text-[18px]"
                    textColor="rgba(12, 15, 74, 0.5)"
                    >Try a different email</Text
                  >

                  <InputSelect
                    class="tw-max-w-full !tw-w-[300px] md:!tw-w-full"
                    placeholder="Select an email"
                    density="compact"
                    :items="['sampleemail@gmail.com', 'sampleemail2@gmail.com']"
                    variant="outlined"
                  />
                </div>
              </div>
            </template>
          </Card> -->

          <!-- AIR SOURCE HEAT PUMP BANNER -->
          <!-- <Card
            class="!tw-rounded-[16px] tw-p-6 !tw-box-border"
            :withBorder="true"
            :isRoundedxl="true"
          >
            <template #content>
              <div class="tw-w-full tw-flex tw-flex-col tw-gap-4">
                <div
                  class="tw-w-full tw-flex tw-flex-col tw-gap-2 tw-items-start tw-pb-4 tw-box-border tw-border-b-dashed-custom"
                >
                  <Text
                    class="!tw-text-[20px] rlg:!tw-text-[32px] rlg:!tw-leading-[28px]"
                    lineHeight="25px"
                    >Does your property have Air Source heat pump?</Text
                  >
                  <Text
                    class="rlg:!tw-leading-[24px]"
                    variant="h6"
                    textWeight="500"
                    lineHeight="18px"
                    >iknowa Heat Pump servicing plan provides you with the peace
                    of mind ensuring that your heat pump is serviced annually
                    and any potential issues are detected early.</Text
                  >
                </div>
                <div
                  class="course-section-card tw-rounded-[8px] tw-w-full tw-flex tw-flex-col tw-items-center tw-gap-4 rmd:!tw-gap-8 tw-p-4 tw-box-border rmd:!tw-flex-row"
                >
                  <img
                    class="tw-w-full rmd:!tw-w-[50%] rlg:!tw-w-auto"
                    :src="require('@/assets/images/heat-pump-banner.png')"
                    alt="iknowa banner"
                  />
                  <div
                    class="tw-w-full tw-gap-4 rlg:!tw-w-[39%] tw-flex tw-flex-col tw-items-start rmd:!tw-gap-6"
                  >
                    <Text variant="h4" lineHeight="24px"
                      >iknowa Heat Pump Servicing Plan</Text
                    >
                    <div
                      class="tw-w-full tw-flex tw-flex-col tw-items-start tw-gap-4"
                    >
                      <Text
                        class="rlg:!tw-leading-[24px]"
                        variant="h6"
                        textWeight="500"
                        lineHeight="18px"
                        >iknowa Heat Pump servicing plan provides you with the
                        peace of mind ensuring that your heat pump is serviced
                        annually and any potential issues are detected
                        early.</Text
                      >
                      <Button
                        @click="openPdf"
                        variant="accent"
                        label="view example policy details"
                      ></Button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </Card> -->

          <!-- MANAGE EMAILS -->
          <Card
            class="!tw-max-h-[unset] tw-w-full tw-box-border tw-p-6 md:tw-p-4"
            :withBorder="true"
            :isRoundedxl="true"
          >
            <template #content>
              <section
                class="tw-flex tw-flex-col tw-items-start tw-justify-between"
              >
                <div
                  class="tw-w-full tw-flex tw-flex-col tw-items-start tw-gap-6 tw-pb-4"
                >
                  <div class="tw-flex tw-flex-col tw-items-start tw-gap-4">
                    <Text class="!tw-text-[22px]">Manage emails</Text>
                  </div>
                  <div
                    class="tw-w-full tw-flex tw-flex-col tw-items-start tw-gap-4"
                  >
                    <div
                      class="tw-w-full tw-flex tw-items-start tw-justify-between tw-border-0 tw-pb-2"
                    >
                      <div class="tw-flex tw-items-center tw-gap-2">
                        <div
                          class="tw-w-auto tw-px-2 tw-box-border tw-rounded-[4px] tw-bg-[rgba(79,85,240,0.1)] tw-flex tw-items-center tw-justify-center tw-border tw-border-solid tw-border-[#4F55F0]"
                        >
                          <Text
                            variant="xsmall"
                            textWeight="700"
                            textColor="#4F55F0"
                            >Primary</Text
                          >
                        </div>
                        <Text variant="p" textWeight="400">{{
                          userEmail
                        }}</Text>
                      </div>
                    </div>
                    <div
                      class="tw-w-full tw-flex tw-items-start tw-justify-between tw-border-0 tw-pb-2 !tw-gap-[.3rem] s540:!tw-flex-col"
                      v-for="email in manageEmailList"
                      :key="email.id"
                    >
                      <div class="tw-flex tw-items-center tw-gap-2">
                        <ApproveChip
                          v-if="email?.invitationStatus === 'accepted'"
                        />
                        <PendingChip
                          v-if="email?.invitationStatus === 'pending'"
                        />
                        <Text variant="p" textWeight="400">{{
                          email?.aliasEmail
                        }}</Text>
                      </div>
                      <div class="tw-flex tw-items-center tw-gap-2">
                        <DeleteActionChip @click="onClickDeleteEmail(email)" />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-2 tw-pt-5 tw-border-t-solid-custom"
                >
                  <Button
                    class="!tw-w-auto !tw-p-0 tw-box-border !tw-text-[#FFA500]"
                    variant="custom"
                    @click="toggleAddEmailModal(true)"
                    label="Add Email Address"
                    activeBgColor="transparent"
                    width="auto"
                    :isCapitalize="true"
                    :fontWeight="'700'"
                  >
                    <template #postfix>
                      <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
                    </template>
                  </Button>
                </div>
              </section>
            </template>
          </Card>

          <!-- <Card
            :withBorder="true"
            :isRoundedxl="true"
            class="!tw-rounded-[16px] tw-p-6 !tw-box-border !tw-max-h-[unset] !tw-h-auto tw-w-full"
          >
            <template #content>
              <main
                class="tw-w-full tw-flex tw-flex-col tw-justify-between tw-h-full"
              >
                <section
                  class="tw-w-full tw-flex tw-items-center tw-justify-between tw-pb-4 tw-box-border tw-border-b-dashed-custom"
                >
                  <div
                    class="email_title tw-flex tw-items-center tw-gap-4 tw-justify-between tw-w-full tw-max-w-full"
                  >
                    <Text class="!tw-text-[20px] rlg:!tw-text-[32px]"
                      >Manage Emails</Text
                    >
                  </div>
                  <Button
                    class="!tw-w-auto !tw-p-0 tw-box-border !tw-text-[#FFA500]"
                    variant="custom"
                    @click="toggleAddEmailModal(true)"
                    label="Add Email Address"
                    activeBgColor="transparent"
                    width="auto"
                  >
                    <template #postfix>
                      <v-icon icon="mdi-chevron-right" color="#FFA500"></v-icon>
                    </template>
                  </Button>
                </section>
                <section
                  class="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-pt-5 tw-box-border"
                >
                  <div
                    class="tw-w-full tw-flex tw-items-start tw-justify-between"
                  >
                    <div class="tw-flex tw-items-center tw-gap-2">
                      <Text variant="h6">{{ processedEmail(userEmail) }}</Text>
                    </div>
                  </div>
                  <div
                    class="tw-w-full tw-flex tw-items-start tw-justify-between"
                    v-for="email in manageEmailList"
                    :key="email.id"
                  >
                    <div
                      class="tw-flex tw-items-center tw-gap-2 md:!tw-flex-col md:!tw-items-start"
                    >
                      <Text variant="h6">{{ email?.aliasEmail }}</Text>
                      <ApproveChip
                        v-if="email?.invitationStatus === 'accepted'"
                      />
                      <PendingChip
                        v-if="email?.invitationStatus === 'pending'"
                      />
                    </div>
                    <div class="tw-flex tw-items-center tw-gap-2">
                      <DeleteActionChip @click="onClickDeleteEmail(email)" />
                    </div>
                  </div>
                </section>
              </main>
            </template>
          </Card> -->

          <!-- BENEFITS -->
          <!-- <Card
            class="!tw-rounded-[16px] tw-p-6 !tw-box-border"
            :withBorder="true"
            :isRoundedxl="true"
          >
            <template #content>
              <div
                class="tw-flex tw-flex-col tw-gap-4 tw-items-start rlg:!tw-flex-row rlg:!tw-flex-row-between"
              >
                <Text
                  class="!tw-text-[24px] rlg:!tw-text-[32px] rlg:!tw-leading-[28px]"
                  lineHeight="26px"
                  >Learn more about iknowa by exploring the benefits.</Text
                >
                <Button
                  class="!tw-w-full rmd:!tw-w-auto"
                  label="Explore iknowa benefits"
                  @click="onClickBenefitsButton"
                ></Button>
              </div>
            </template>
          </Card> -->

          <Card
            class="!tw-max-h-[unset] tw-w-full tw-box-border tw-p-6 md:tw-p-4"
            :withBorder="true"
            :isRoundedxl="true"
          >
            <template #content>
              <section
                class="tw-flex tw-items-center tw-gap-8 md:!tw-gap-6 md:!tw-flex-col md:tw-items-start"
              >
                <img
                  class="!tw-w-[124px]"
                  :src="require('@/assets/images/iknowa-benefits.png')"
                />
                <section
                  class="tw-w-full tw-flex tw-flex-col tw-items-start tw-justify-between"
                >
                  <div
                    class="tw-flex tw-flex-col tw-items-start tw-gap-6 tw-pb-2"
                  >
                    <div class="tw-flex tw-flex-col tw-items-start tw-gap-4">
                      <Text class="!tw-text-[22px]"
                        >Learn more about iknowa</Text
                      >
                      <Text variant="h6" textWeight="400"
                        >Explore everything that the iknowa can do.</Text
                      >
                    </div>
                    <div
                      class="tw-flex tw-flex-col tw-items-start tw-gap-4"
                    ></div>
                  </div>
                  <div
                    class="tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-2 tw-pt-5 tw-border-t-solid-custom"
                  >
                    <Button
                      class="!tw-w-auto !tw-p-0 tw-box-border !tw-text-[#FFA500]"
                      variant="custom"
                      @click="onClickBenefitsButton"
                      label="Explore iknowa benefits"
                      activeBgColor="transparent"
                      width="auto"
                      :isCapitalize="true"
                      :fontWeight="'700'"
                    >
                      <template #postfix>
                        <v-icon
                          icon="mdi-chevron-right"
                          color="#FFA500"
                        ></v-icon>
                      </template>
                    </Button>
                  </div>
                </section>
              </section>
            </template>
          </Card>

          <Card
            class="!tw-max-h-[unset] tw-w-full tw-box-border tw-p-6 md:tw-p-4"
            :withBorder="true"
            :isRoundedxl="true"
          >
            <template #content>
              <section
                class="tw-flex tw-items-start tw-gap-8 md:!tw-gap-6 md:!tw-flex-col"
              >
                <img
                  class="!tw-w-[124px]"
                  :src="require('@/assets/images/upcoming-events.svg')"
                />
                <section
                  class="tw-w-full tw-flex tw-flex-col tw-items-start tw-justify-between"
                >
                  <div class="tw-flex tw-flex-col tw-items-start tw-gap-6">
                    <div class="tw-flex tw-flex-col tw-items-start tw-gap-4">
                      <Text class="!tw-text-[22px]"
                        >Stay tuned for more events in 2025</Text
                      >
                      <Text variant="h6" textWeight="400"
                        >Join us and connect with peers, gain insights from
                        experts, and take steps towards advancing your career in
                        the trade industry.</Text
                      >
                    </div>

                    <!-- <div
                      class="tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-2 tw-pt-5 tw-border-t-solid-custom"
                    >
                      <Button
                        class="!tw-w-auto !tw-p-0 tw-box-border !tw-text-[#FFA500]"
                        variant="custom"
                        @click="onClickViewGoogleEvent"
                        label="Explore Our events"
                        activeBgColor="transparent"
                        width="auto"
                        :isCapitalize="true"
                        :fontWeight="700"
                      >
                        <template #postfix>
                          <v-icon
                            icon="mdi-chevron-right"
                            color="#FFA500"
                          ></v-icon>
                        </template>
                      </Button>
                    </div> -->
                  </div>
                  <!-- <div
                    class="tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-2 tw-pt-5 tw-border-t-solid-custom"
                  >
                    <Button
                      class="!tw-w-auto !tw-p-0 tw-box-border !tw-text-[#FFA500]"
                      variant="custom"
                      @click="onClickBenefitsButton"
                      label="Explore our events"
                      activeBgColor="transparent"
                      width="auto"
                      :isCapitalize="true"
                      :fontWeight="700"
                    >
                      <template #postfix>
                        <v-icon
                          icon="mdi-chevron-right"
                          color="#FFA500"
                        ></v-icon>
                      </template>
                    </Button>
                  </div> -->
                </section>
              </section>
            </template>
          </Card>

          <!-- GET YOUR SERVICE PLAN -->
          <!-- <div class="service-plan-card">
            <h2>Does Your Property have Air Source heat pump?</h2>
            <p>
              Save with iknowa Heat Pump servicing plan. Providing you with
              peace of mind, ensuring that your heat pump is annually serviced
              and any potential issues are detected early.
            </p>
            <div class="service-plan-card__footer">
              <v-btn
                class="bright-blue-text"
                variant="text"
                append-icon="mdi-chevron-right"
                @click="onClickOpenServicePlanModal"
              >
                Get Your Service Plan
              </v-btn>
            </div>
          </div> -->
        </section>
      </div>
    </main>
    <AddEmailModal
      v-if="isShowAddEmailModal"
      @on-close="toggleAddEmailModal($event)"
      @onClickAdd="onClickAddEmail"
    />
    <EmailConformModal
      v-if="isShowAddEmailConformModal"
      :modalType="emailConfirmModalType"
      :manageEmail="manageEmail"
      @on-close="toggleAddEmailConfirmModal"
    />

    <PropertyOwnerWorkStationModal
      v-if="isShowPropertyOwnerWorkStationModal"
      @onClickCloseTab="onClickClosePOWorkstationModal"
    />
    <PropertyOwnerSubscriptionModal
      v-if="isShowPropertyOwnerSubscriptionModal"
      :SelectWorkstationType="true"
      @closeSelectWorkstationTypeModal="onClickCloseButton"
    />
  </div>
  <WorkstationVerificationModal
    v-if="openedModel === modelName.VERIFYMODAL"
    :onClickCloseTab="onClickCloseModel"
  />

  <!-- <KnowYourPropertyComponentModal
    v-if="user.loginAttempt === 1 && isShowknowpropertyModal"
    @onClickCloseWelcomeModal="onClickCloseWelcomeModal"
  /> -->
  <!-- <UpgradePremiumWorkstationModal
    v-if="isShowUpgradePremiumModal"
    @closeUpgradePremiumModal="closeUpgradePremiumModal"
  /> -->
  <PropertyOwnerSubscriptionModal
    v-if="isShowUpgradePremiumModal"
    @closeSubscriptionModal="closeUpgradePremiumModal"
    @closeSelectWorkstationTypeModal="closeUpgradePremiumModal"
  />
  <AddAddressModal
    v-if="openedModel === modelName.ADDRESSMODAL"
    @on-close-success="onClickCloseModel"
    @on-close="onClickCloseModel"
  />
  <PropertyServicePlanModal
    v-if="isOpenServicePlanModal"
    @on-close="onClickOpenServicePlanModal"
    @on-action="onServicePlanAction"
  />

  <PropertyOwnerBenefitsModal
    v-if="isShowPropertyOwnerBenefitsModal"
    @onClickClosePreviewModalButton="toggleBenefitModal"
  />
  <!-- @toggleBenefitModal="toggleBenefitModal" -->
  <!-- <TradePersonBenefitsModal
    v-if="isShowPropertyOwnerBenefitsModal"
    @toggleBenefitModal="toggleBenefitModal"
  /> -->
  <v-dialog
    width="350px"
    v-model="launchInvitedWorkstation"
    class="modal_box action-modal"
    persistent
  >
    <div class="modal_wrapper">
      <main class="main">
        <div>
          <div class="loading-dots">
            <span class="dot dot-red"></span>
            <span class="dot dot-blue"></span>
            <span class="dot dot-green"></span>
          </div>
          <p class="switching-text">{{ switchingText }}</p>
          <v-stepper class="onboard-stepper" v-model="currentSelectedTab.name">
            <v-stepper-header class="onboard-stepper__header">
              <v-stepper-item
                class="onboard-stepper__item"
                v-for="(step, index) in tabListItems"
                :key="index"
                :value="step.name"
              ></v-stepper-item>
            </v-stepper-header>
          </v-stepper>
        </div>
      </main>
    </div>
  </v-dialog>
  <CommonDialog
    v-if="isShowIDCheckModal"
    :className="['property_owner_id_Check_modal']"
    :onClickBackButton="onClickCloseIDCheckButton"
    :HeaderTittle="HeaderTittle"
    title="We need to verify your identity"
    width="650px"
    height="auto"
    ><template v-slot:body>
      <div class="modal_content">
        <div class="description">
          <p>Hello We will require your passport or driver’s license.</p>
          <p>
            Verified ID is required to ensure trust security and compliance on
            Iknowa.
          </p>
        </div>
        <div class="content">
          <div class="your-img-wrap">
            <img class="open-logo" src="@/assets/icons/id-check.svg" />
          </div>
          <v-btn class="button button-orange w-full" v-if="isShowNextButton"
            >Next</v-btn
          >
          <!-- <a
            v-if="selectedType === 'Individual'"
            class="btn_link"
            :href="siltRedirectUrl"
            >Start ID verification</a
          > -->

          <v-btn
            :loading="isLoading"
            class="button button-purple-border"
            @click="onClickKycTypeIdVerification"
            width="100%"
            >Start ID verification
          </v-btn>

          <!-- <a
            v-if="selectedType === 'Company'"
            class="btn_link"
            @click.prevent="onClickIdVerification"
            >Start ID verification</a
          > -->
        </div>
      </div>
    </template>
  </CommonDialog>
  <PropertySuccessModal v-if="isShowSuccessModel" @close="toggleSuccessModel" />
  <ClientFlowServiceAgreementModal
    v-if="isShowClientAgreementModal"
    @on-close="onToggleClientAgreementModal"
    @on-confirm="onToggleClientAgreementModal"
  />
</template>

<script lang="ts" setup>
import { computed, onBeforeMount, ref, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { USER_STORE } from "@/store/modules/user";
import { WORKSTATION } from "@/store/modules/workstation";
// import SpeedMeterComponent from "@/modules/dashboard/components/SpeedMeterComponent.vue";
import PropertyOwnerBenefitsModal from "@/modules/dashboard/components/PropertyOwnerBenefitsModal.vue";
import { VUE_APP_PROPERTY_DATA_API } from "@/config";
import UserProfileLogo from "@/core/components/UserProfileLogo.vue";
import WorkStationProfileLogo from "@/core/components/WorkStationProfileLogo.vue";
// import KnowYourPropertyComponentModal from "@/modules/dashboard/components/KnowYourPropertyComponent.vue";
import {
  getCombineSameProjectReview,
  getLocalStorageWithExpiry,
  isAliased,
  parseJSON,
  setLocalStorageWithExpiry,
} from "@/core/utils/common";
import { RolesEnum, UserRolesEnum } from "@/core/enums/RolesEnum";
import { PROJECT_STORE } from "@/store/modules/project";
import {
  BusinessDetailTypeEnum,
  UserVerificationStatusEnum,
  MemberInvitationStatusEnum,
} from "@/core/enums/RolesEnum";
import Text from "@/core/components/ui/general/Text.vue";
import Card from "@/core/components/ui/general/Card.vue";
import Button from "@/core/components/ui/general/Button.vue";
import ProfileThumbnail from "@/core/uni-components/ProfileThumbnail.vue";
import Postcode from "@/core/uni-components/Chips/Postcode.vue";
import { getImageApiUrl, getImageStringToImageURL } from "@/core/utils/common";
import $axios from "@/core/utils/axios-api-config";
import { MEMBER_STORE } from "@/store/modules/manage-member";
import WorkstationVerificationModal from "@/modules/workstation/components/WorkstationVerificationModal.vue";
import PropertyOwnerWorkStationModal from "@/modules/workstation/propertyowner/pages/PropertyOwnerWorkStationModal.vue";
import WorkStationVerificationLock from "@/core/components/WorkStationVerificationLock.vue";
import PropertyOwnerSubscriptionModal from "@/modules/workstation/propertyowner/pages/PropertyOwnerSubscriptionModal.vue";

// WIDGETS
import PreferredContractorsWidget from "@/modules/dashboard/components/Widgets/PreferredContractorsWidget.vue";
import PropertiesWidget from "@/modules/dashboard/components/Widgets/PropertiesWidget.vue";
import ProjectsOverviewWidget from "@/modules/dashboard/components/Widgets/ProjectsOverviewWidget.vue";
import TeamWidget from "@/modules/dashboard/components/Widgets/TeamWidget.vue";
import CalendarEventWidget from "@/modules/dashboard/components/Widgets/CalendarEventWidget.vue";
import AddEmailActionChip from "./Chips/AddEmailActionChip.vue";
import AddEmailModal from "@/modules/dashboard/components/Modals/AddEmailModal.vue";
import ApproveChip from "@/modules/dashboard/components/Chips/ApproveChip.vue";
import DeleteActionChip from "@/modules/dashboard/components/Chips/DeleteActionChip.vue";
import PendingChip from "@/modules/dashboard/components/Chips/PendingChip.vue";
import EmailConformModal from "./Modals/EmailConformModal.vue";
import manageEmailService from "@/core/services/manage-email.service";
import { PROPERTY_STORE } from "@/store/modules/property";
import UpgradePremiumWorkstationModal from "@/modules/workstation/propertyowner/pages/UpgradePremiumWorkstationModal.vue";
import { DASHBOARD_ROUTE } from "@/modules/dashboard/routes";
import MyWorkstationChip from "./Chips/MyWorkstationChip.vue";
import LunchIcon from "@/core/components/icons/LunchIcon.vue";
import TrashIcon from "@/core/components/icons/TrashIcon.vue";
import Loader from "@/core/components/common/Loader.vue";
import AddAddressModal from "@/modules/dashboard/components/Modals/AddAddressModal.vue";
import { PREFERRED_CONTRACTORS } from "@/store/modules/preferred-contractors";
import { VIEW_RATING_ROUTE } from "@/modules/view-rating/routes";
import { REVIEW_RATING_STORE } from "@/store/modules/review-rating";
import { ProjectRatingTypeEnum } from "@/core/enums/ProjectsEnum";
import {
  CreateProjectRatingPayloadWithId,
  reviewRatingState,
} from "@/core/models/project-rating";
import { UserWorkstationVerificationType } from "@/core/enums/RolesEnum";
import ProfileVerificationStatusChip from "./Chips/ProfileVerificationStatusChip.vue";
import PropertyServicePlanModal from "@/modules/dashboard/components/Modals/PropertyServicePlanModal.vue";
import InputSelect from "@/core/components/common/InputSelect.vue";
import TradePersonBenefitsModal from "@/modules/dashboard/components/TradePersonBenefitsModal.vue";
import pdfFile from "@/assets/pdf/iknowa Heat Pump Service Plan (EXAMPLE ONLY).pdf";
import { UserWorkstationSubStatusEnum } from "@/core/enums";
import { ADD_PROPERTY_ROUTE } from "@/modules/properties/routes";
import { PROPERTY_TYPE } from "@/modules/properties/constants";
import { AUTH_SESSION_EXPIRY, AUTH_USER } from "@/core/constants";
import userService from "@/core/services/user.service";
import NotificationIcon from "@/core/components/icons/NotificationIcon.vue";
import CommonDialog from "@/core/components/CommonDialog.vue";
import ChipState from "@/core/components/common/ChipState.vue";
import CheckGreenShield from "@/core/components/icons/CheckGreenShield.vue";
import InfoIconRed from "@/core/components/icons/InfoIconRed.vue";
import PropertySuccessModal from "@/core/components/common/PropertySuccessModal.vue";
import ClientFlowServiceAgreementModal from "@/modules/clients/components/model/ClientFlowServiceAgreementModal.vue";

const store = useStore();
const router = useRouter();
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const progressValue = ref("50%");
const propertyListData = ref([]) as any;
const loading = ref(false);
const isWorkstationEmpty = ref(false);
const WorkStationInvitation = ref();
const InviteMemberImage = ref();
const modelName = {
  VERIFYMODAL: "verifyModal",
  ADDRESSMODAL: "addressModal",
};

const teamMemberLoading = ref(false);
const members = ref([]) as any;
const isLoading = ref(false) as any;

const openedModel = ref("");
const isShowUpgradePremiumModal = ref(false);
const isShowPropertyOwnerWorkStationModal = ref(false);
const isShowPropertyOwnerSubscriptionModal = ref(false);
const isShowknowpropertyModal = ref(false);
const userImage = computed(() => store.getters[`${USER_STORE}/userImage`]);
const userFullName = computed(() => {
  return `${user.value?.firstName} ${user.value?.lastName}`;
});
const isShowAddEmailModal = ref(false);
const isShowAddEmailConformModal = ref(false);
const manageEmailList: any = ref([]);
const emailConfirmModalType = ref("");
const manageEmail = ref() as any;
const statusModal = { PAYMENT: "payment", KYC: "kyc" };
const currentStatusModalName = ref("");
const currentModalStatus = ref("");
const isShowPropertyOwnerBenefitsModal = ref(false);
const launchInvitedWorkstation = ref(false);
const isShowIDCheckModal = ref(false);
const HeaderTittle = ref("");
const isShowNextButton = ref(false);
const isShowSuccessModel = ref(false);
const isShowClientAgreementModal = ref(false);
const tabListItems = [
  {
    tab: "step1",
    name: "Switching Account...",
  },
  {
    tab: "step2",
    name: "Fetching Account Data...",
  },
  {
    tab: "step3",
    name: "Loading Account...",
  },
];

const toolTipProfile = computed(() => {
  if (userWorkStationStatus.value == 2)
    return ` <h3 style='margin: 0; color: #40ae42;'>
              KYC Verified
          </h3>
            <p style='margin: 5px 0 0;'> Your identity has been successfully verified! Your account is now fully secure and ready to access all features. Thank you for completing the verification process. </p>
            `;
  else
    return `<h3 style='margin: 0; color: #faa500;'>
            KYC Pending
          </h3>
          <p style='margin: 5px 0 0;'>
            Your identity verification is pending. This step ensures the security of your account, protecting it from fraud and unauthorized access.
          </p>`;
});

const switchingText = ref("Switching Account...");
const currentSelectedTab = ref(tabListItems[0]);
onUnmounted(() => {
  store.commit(`${WORKSTATION}/setPropertyOwnerWorkStationModelState`, false);
});

const userAllWorkStation = computed(
  () => store.getters[`${WORKSTATION}/getUserWorkstations`]
);
const userNotifications = computed(
  () => store.getters[`${USER_STORE}/userNotifications`]
);
const filteredUserAllWorkStation = computed(() =>
  userAllWorkStation.value.filter(
    (workstation: any) => workstation.name !== "Default"
  )
);

const WorkStationSiltVerificationStatus = computed(
  () => store.getters[`${WORKSTATION}/getWorkStationSiltVerificationStatus`]
);
const isshowKycModel = computed(
  () => store.getters[`${WORKSTATION}/isshowKycModel`]
);

const isWorkstationLoading = computed(
  () => store.getters[`${WORKSTATION}/loadingWorkstation`]
);

const onClickToggleTradesWorkStation = () => {
  // isShowPropertyOwnerWorkStationModal.value = true;
  router.push({
    path: `create-workstation/property-owner`,
  });
  // store.commit(
  //   `${WORKSTATION}/setPropertyOwnerWorkStationModelState`,
  //   !isShowCreatePropertyOwnerWorkStationModal.value
  // );
};

const isShowCreatePropertyOwnerWorkStationModal = computed(
  () => store.getters[`${WORKSTATION}/getPropertyOwnerWorkStationModelState`]
);
const onClickCloseWelcomeModal = () => {
  isShowknowpropertyModal.value = false;
  localStorage.setItem("isShowknowpropertyPopup", JSON.stringify(true));
};
const route = useRoute();
const redirectEscrowPayment = () => {
  if (
    user.value?.role?.name === UserRolesEnum.PROPERTY_OWNER &&
    route.query.result &&
    route.query.paymentInitiationId
  ) {
    const data = localStorage.getItem("escrowPayment") as any;
    if (data) {
      const details = parseJSON(data);
      store.dispatch(
        `${PROJECT_STORE}/setProjectRedirectTabName`,
        "escrow-account"
      );
      router.push({
        path: `/project-details/${details?.url}`,
        query: {
          result: route.query.result,
          paymentInitiationId: route.query.paymentInitiationId,
        },
      });
      // localStorage.removeItem("escrowPayment");
    }
  }
};

const onClickViewGoogleEvent = () => {
  const link = "https://qc6kd2k4jjk.typeform.com/to/Z37e81Id";
  window.open(link, "_blank");
};

const onClickBenefitsButton = () => {
  isShowPropertyOwnerBenefitsModal.value = true;
};

// const toggleBenefitModal = () => {
//   isShowPropertyOwnerBenefitsModal.value = false;
// };
const toggleBenefitModal = () => {
  isShowPropertyOwnerBenefitsModal.value =
    !isShowPropertyOwnerBenefitsModal.value;
};
const getPreferredContractorsConnection = async () => {
  try {
    let params = {
      status: "accepted",
    };
    await store.dispatch(
      `${PREFERRED_CONTRACTORS}/getPreferredContractorsConnection`,
      {
        userId: user.value?.id,
        params: params,
      }
    );
  } catch (err) {
    console.log();
  }
};
const getAllProjectReviewList = computed<
  reviewRatingState["allProjectReviewList"]
>(() => store.getters[`${REVIEW_RATING_STORE}/getAllProjectReviewList`]);
const combineSameProjectReview = computed(() =>
  getCombineSameProjectReview(getAllProjectReviewList.value)
);
const getAverageOfAllProjectReview = computed(() => {
  const totalAvg = combineSameProjectReview.value.reduce(
    (totalRating: number, review: any) => {
      totalRating += review.totalAvg || 0;
      return totalRating;
    },
    0
  );
  return totalAvg
    ? Math.round((totalAvg * 100) / combineSameProjectReview.value.length) / 100
    : totalAvg;
});

const getCheckoutUserKYC = async () => {
  try {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (!params?.silt_user_id) return;
    const soloTraderVerificationData = await store.dispatch(
      `${WORKSTATION}/getWorkStationSiltVerificationStatus`,
      {
        id: params.silt_user_id,
        verificationType: UserWorkstationVerificationType.KYC,
      }
    );
    console.log(soloTraderVerificationData);
    await store.dispatch(
      `${WORKSTATION}/saveWorkstationVerificationData`,
      soloTraderVerificationData
    );

    let status = UserVerificationStatusEnum.PENDING_VERIFICATION;
    if (soloTraderVerificationData.status === "SUCCESS") {
      status = UserVerificationStatusEnum.VERIFIED;
    }

    const data = {
      userId: user.value?.id,
      userWorkstationId: activeUserWorkstation.value?.id,
      formData: {
        verificationStatus: status,
      },
    };
    await store.dispatch(`${WORKSTATION}/updateUserWorkstationStatus`, data);

    currentStatusModalName.value = statusModal.KYC;
    currentModalStatus.value = soloTraderVerificationData?.status;
    openedModel.value = "";
    await store.dispatch(`${USER_STORE}/initializeUserState`);
  } catch (error: any) {
    if (error?.response?.status === 400) {
      currentStatusModalName.value = statusModal.KYC;
      openedModel.value = "";
      currentModalStatus.value = "ERROR";
      return;
    }
  }
};

const getTeamMember = async () => {
  try {
    teamMemberLoading.value = true;
    const response = await store.dispatch(`${MEMBER_STORE}/getTeamMember`, {
      userId: user.value?.id,
    });

    members.value = await Promise.all(
      response.map(async (member: any) => {
        if (member?.toUserId) {
          if (member?.toUser?.userPublicProfile) {
            member.userProfileImageUrl = await getImageStringToImageURL(
              member?.toUser?.userPublicProfile?.profileImage
            );
          } else {
            member.userProfileImageUrl = null;
          }
        } else {
          member.userProfileImageUrl = null;
        }
        return member;
      })
    );
  } catch (err) {
    console.log();
  } finally {
    console.log("members value", members.value);
    teamMemberLoading.value = false;
  }
};
const onClickCloseIDCheckButton = () => {
  isShowIDCheckModal.value = false;
};
const onClickKycTypeIdVerification = async () => {
  try {
    isLoading.value = true;
    const currentDomain = window.location.origin;
    const payload = {
      callback: `${currentDomain}/dashboard`,
      vendor_data: `${user.value.id}-${activeUserWorkstation.value.id}`,
    };

    const response = await store.dispatch(
      `${WORKSTATION}/sendUserKYCProcessMail`,
      payload
    );

    const redirectUrl = response?.url;
    if (redirectUrl) {
      window.location.href = redirectUrl;
      await store.dispatch(`${WORKSTATION}/setIsShowKycModel`, false);
      isLoading.value = false;
    } else {
      isLoading.value = false;
    }
  } catch (err) {
    isLoading.value = false;
  }
};

const toggleSuccessModel = () => {
  isShowSuccessModel.value = !isShowSuccessModel.value;
  if (!isShowSuccessModel.value) {
    onToggleClientAgreementModal();
  }
};
const onToggleClientAgreementModal = () => {
  isShowClientAgreementModal.value = !isShowClientAgreementModal.value;
};

const checkIsClientRaiseEstimateFlow = () => {
  const isClientRaiseEstimateFlow = localStorage.getItem(
    "isClientRaiseEstimateFlow"
  );
  if (parseJSON(isClientRaiseEstimateFlow)) {
    toggleSuccessModel();
  }
  localStorage.removeItem("isClientRaiseEstimateFlow");
};
onBeforeMount(async () => {
  if (isshowKycModel.value) {
    isShowIDCheckModal.value = true;
  }
  checkIsClientRaiseEstimateFlow();
  redirectEscrowPayment();
  getWorkStationInvitation();
  getCheckoutUserKYC();
  getPreferredContractorsConnection();
  await store.dispatch(
    `${REVIEW_RATING_STORE}/getAllProjectReviewList`,
    ProjectRatingTypeEnum.PROPERTY_OWNER
  );
  await store.dispatch(
    `${WORKSTATION}/getPropertyOwnerUserWorkstations`,
    user.value.id
  );
  getTeamMember();
  setManageEmailList();
  isShowknowpropertyModal.value = parseJSON(
    localStorage.getItem("isShowknowpropertyPopup")
  )
    ? false
    : true;
  try {
    loading.value = true;
    // const response = await axios.get(
    //   `https://api.propertydata.co.uk/sourced-properties?key=${VUE_APP_PROPERTY_DATA_API}&list=repossessed-properties&postcode=${user.value?.postcode}`
    // );
    // if (response.status === 200) {
    // propertyListData.value = response.data.properties[0];
    propertyListData.value = {
      id: "R142978070",
      address: "9 Cornwallis Avenue",
      addressLine1: "Folkestone, Kent, UK",
      postcode: "CT19 5JA",
      type: " Leasehold | LH2RYU",
      bedrooms: 3,
      price: 2250000,
      sqf: 1895,
      days_on_market: 47,
      sstc: 0,
      lat: "51.49305000",
      lng: "-0.15286000",
      distance_to: "0.73",
      highest_offer: null,
    };

    await store.dispatch(`${PROPERTY_STORE}/fetchProperties`, {});

    // }

    // addressListfromPostcode.value = response.data.results;
  } catch (error) {
    console.log("error", error);
  } finally {
    loading.value = false;
  }
});

const onClickSelectWorkstation = () => {
  router.push("/select-workstation");
};
const onClickUpdateProfile = () => {
  router.push("/profile");
};

const userName = computed(() => {
  return `${user.value?.firstName}`;
});

const userEmail = computed(() => {
  return `${user.value?.email}`;
});

const userWorkStationImage = computed(
  () => store.getters[`${USER_STORE}/userWorkStationImage`]
);

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);
const getWorkstationAddress = (type: string) => {
  if (type === BusinessDetailTypeEnum.LIMITED_COMPANY) {
    return user.value?.userBusinessDetails?.businessAddress;
  }
  return user.value?.userBusinessDetails?.residencyAddressLine;
};

const getWorkstationPostcode = (type: string) => {
  if (type === BusinessDetailTypeEnum.LIMITED_COMPANY) {
    return user.value?.userBusinessDetails?.businessPostcode;
  }
  return `${user.value?.userBusinessDetails?.residencyPostcode},`;
};

const userWorkStationStatus = computed(
  () =>
    store.getters[`${WORKSTATION}/activeUserWorkstation`]?.verificationStatus
);

const userWorkstationStatusByWord = computed(() => {
  if (userWorkStationStatus.value === UserVerificationStatusEnum.NOT_ADDED) {
    return "PO-UNVERIFIED";
  }
  if (userWorkStationStatus.value === UserVerificationStatusEnum.VERIFIED) {
    return "PO-VERIFIED";
  }
  if (
    userWorkStationStatus.value ===
    UserVerificationStatusEnum.PENDING_VERIFICATION
  ) {
    return "PO-PENDING";
  }
  return "";
});

const userBusinessType = computed(() => user.value?.userBusinessDetails?.type);

// NOTE: Must be isUserProfileVerifiable
const isUserProfileVerified = computed(
  () => store.getters[`${USER_STORE}/isUserProfileVerifiable`]
);

const isUserTaxDetailsAdded = computed(
  () =>
    user.value?.userBusinessDetails?.taxReferenceNumber &&
    user.value?.userBusinessDetails?.ukVatNumber
);

const workstationSetupProgress = computed(() => {
  if (
    !isUserWorkstationIsDefault.value &&
    isUserProfileVerified.value &&
    isUserTaxDetailsAdded.value
  ) {
    return 66;
  }
  if (!isUserWorkstationIsDefault.value) {
    return 33;
  }
  return 0;
});

const isUserWorkstationIsDefault = computed(
  () =>
    activeUserWorkstation.value?.name === "Default" &&
    activeUserWorkstation.value?.status === "active"
);

const acceptInvitaionLoader = ref(false);
const acceptInvitationWorkstationId = ref<null | string>(null);
const onClickAcceptWSInvitation = async (token: string) => {
  if (
    user.value?.userKYCVerificationStatus ===
    UserVerificationStatusEnum.VERIFIED
  ) {
    try {
      acceptInvitaionLoader.value = true;
      acceptInvitationWorkstationId.value = token;
      await store.dispatch(`${USER_STORE}/acceptWorkStationInvitation`, {
        userId: user.value?.id,
        key: token,
      });
      getWorkStationInvitation();
      await store.dispatch(
        `${WORKSTATION}/getPropertyOwnerUserWorkstations`,
        user.value.id
      );
    } catch (err) {
      console.log();
    } finally {
      acceptInvitaionLoader.value = false;
      acceptInvitationWorkstationId.value = null;
    }
  } else {
    openedModel.value = "verifyModal";
  }
};

const onClickCloseModel = async () => {
  openedModel.value = "";
  await store.dispatch(`${USER_STORE}/getAccountStatuses`);
};

const deleteInvitedWorkstationLoader = ref(false);
const deleteInvitedWorkstationId = ref<null | number>(null);
const onClickRejectWSInvitation = async (id: number) => {
  try {
    deleteInvitedWorkstationLoader.value = true;
    deleteInvitedWorkstationId.value = id;
    const response = await store.dispatch(
      `${MEMBER_STORE}/deleteMemberDetails`,
      {
        userId: user.value?.id,
        userMemberId: id,
      }
    );
    getWorkStationInvitation();
    await store.dispatch(
      `${WORKSTATION}/getPropertyOwnerUserWorkstations`,
      user.value.id
    );
  } catch (err) {
    console.log();
  } finally {
    deleteInvitedWorkstationLoader.value = false;
    deleteInvitedWorkstationId.value = null;
  }
};

const getWorkStationInvitation = async () => {
  try {
    const response = await store.dispatch(
      `${USER_STORE}/getWorkStationInvitation`,
      user.value?.id
    );
    WorkStationInvitation.value = response;
    const image = getImageApiUrl(
      response[0]?.userWorkstation?.profileImage,
      true
    );
    const encoded: any = await $axios.get(image);
    InviteMemberImage.value = encoded?.publicUrl;
  } catch (err) {
    console.log();
  }
};

const onClickClosePOWorkstationModal = () => {
  isShowPropertyOwnerWorkStationModal.value = false;
};

const freePlan = computed(() => {
  return (
    (userWorkStationStatus.value === UserVerificationStatusEnum.NOT_ADDED &&
      !activeUserWorkstation.value?.subscription) ||
    activeUserWorkstation.value?.subStatus !==
      UserWorkstationSubStatusEnum.SUCCEEDED
  );
});

const properties = computed(
  () => store.getters[`${PROPERTY_STORE}/properties`]
);

const isShowAllws = ref(false);

const numberOfWsShowed = computed(() => {
  if (isShowAllws.value) {
    return filteredUserAllWorkStation.value.length;
  }
  return 3;
});

const toggleShowWs = () => {
  isShowAllws.value = !isShowAllws.value;
};

const onClickAddNewProperty = () => {
  if (freePlan.value && properties.value.length >= 1) {
    onClickUpgradePlan();
  } else {
    router.push({
      name: ADD_PROPERTY_ROUTE,
      params: { type: PROPERTY_TYPE },
    });
  }
};

const onClickVerifyAccount = () => {
  isShowPropertyOwnerSubscriptionModal.value = true;
};

const onClickCloseButton = async () => {
  isShowPropertyOwnerSubscriptionModal.value = false;
  getWorkStationInvitation();
  await store.dispatch(
    `${WORKSTATION}/getPropertyOwnerUserWorkstations`,
    user.value.id
  );
  setManageEmailList();
};
const toggleAddEmailModal = (event: boolean) => {
  isShowAddEmailModal.value = event;
};
const toggleAddEmailConfirmModal = (status = false) => {
  isShowAddEmailConformModal.value = !isShowAddEmailConformModal.value;
  if (status) {
    manageEmailList.value = manageEmailList.value.filter(
      (data: any) => data.id !== manageEmail.value?.id
    );
    manageEmail.value = "";
  }
};

const onClickUpgradePlan = () => {
  isShowUpgradePremiumModal.value = true;
};

const closeUpgradePremiumModal = () => {
  isShowUpgradePremiumModal.value = false;
};

const setManageEmailList = async () => {
  try {
    const response: any = await manageEmailService.getEmailList(user.value?.id);
    manageEmailList.value = response?.aliases;
  } catch (error) {
    console.error("get email list error", error);
  }
};
const onClickDeleteEmail = (data: any) => {
  manageEmail.value = data;
  emailConfirmModalType.value = "delete";
  toggleAddEmailConfirmModal();
};
const onClickAddEmail = (addEmailData: object) => {
  manageEmailList.value.push(addEmailData);
  isShowAddEmailModal.value = false;
  emailConfirmModalType.value = "success";
  toggleAddEmailConfirmModal();
};

const onClickAddress = () => {
  // if (user.value?.address) return;
  openedModel.value = modelName.ADDRESSMODAL;
};

const onWorkStationClick = async (workstationId: number, resetData = true) => {
  try {
    await store.dispatch(`${WORKSTATION}/setLoadingWorkstation`, true);
    if (!workstationId) return;

    const workstation = filteredUserAllWorkStation.value.find(
      (workstation: any) => workstation?.id === workstationId
    );
    if (workstation?.isInvited) {
      await store.dispatch(`${WORKSTATION}/isFirstTimeLaunchWorkStation`, {
        userId: user.value?.id,
        memberId: workstation?.memberId,
      });
    }
    if (workstation?.isInvited && !workstation?.isSameRole) {
      await userService.activateWorkstation(user.value?.id, workstation?.id);
      onClickSwitchUserType();
      return;
    }
    if (resetData) {
      await router.push({ name: DASHBOARD_ROUTE });
      await store.dispatch(`${WORKSTATION}/activateWorkstation`, {
        userId: user.value?.id,
        userWorkstationId: workstationId,
      });
    }
    const workstationVerification = await store.dispatch(
      `${WORKSTATION}/getWorkstationVerificationData`,
      workstationId
    );
    if (
      workstationVerification &&
      workstationVerification?.response?.id &&
      ["KYC", "KYB"].includes(workstationVerification?.verificationType)
    ) {
      await store.dispatch(
        `${WORKSTATION}/getWorkStationSiltVerificationStatus`,
        {
          id: workstationVerification.response.id,
          verificationType: workstationVerification?.verificationType,
        }
      );
    } else {
      await store.dispatch(
        `${WORKSTATION}/setWorkStationSiltVerificationStatus`,
        null
      );
    }
  } catch (error) {
    await store.dispatch(`${WORKSTATION}/setLoadingWorkstation`, false);
    console.log();
  } finally {
    if (resetData) {
      await store.dispatch(`${USER_STORE}/initializeUserState`);
      await store.dispatch(`${USER_STORE}/getAccountStatuses`);
      await store.dispatch(`${PROPERTY_STORE}/fetchProperties`, {});

      await store.dispatch(`${USER_STORE}/setUserCertificates`, user.value?.id);
      await store.dispatch(`${USER_STORE}/setUserInsurances`, user.value?.id);
      await store.dispatch(`${USER_STORE}/getUserSkills`, user.value.id);
      await store.dispatch(`${WORKSTATION}/setLoadingWorkstation`, false);
    }
  }
};

const onClickSwitchUserType = async () => {
  let updatedUser: any;
  // Commented For Now
  try {
    launchInvitedWorkstation.value = true;
    updatedUser = await store.dispatch(`${USER_STORE}/selectAccount`, {
      userId: user.value?.id,
      roleId: RolesEnum.TRADESPERSON,
      email: user.value?.email,
    });
    const authUser = getLocalStorageWithExpiry(AUTH_USER);
    const newUserState = await store.dispatch(
      `${USER_STORE}/getUser`,
      updatedUser?.id
    );
    authUser.userId = newUserState.id;
    authUser.roleId = RolesEnum.TRADESPERSON;
    authUser.aliased = isAliased(newUserState.email);
    // eslint-disable-next-line no-undef
    setLocalStorageWithExpiry(authUser, AUTH_USER, AUTH_SESSION_EXPIRY);
    setTimeout(async () => {
      switchingText.value = "Switching Account...";
      currentSelectedTab.value = tabListItems[0];
      store.commit(`${USER_STORE}/resestStore`);
    }, 1000);
  } catch (error) {
    console.log();
  } finally {
    setTimeout(() => {
      switchingText.value = "Fetching Account Data...";
      currentSelectedTab.value = tabListItems[1];
    }, 2000);
    setTimeout(async () => {
      switchingText.value = "Loading Account...";
      currentSelectedTab.value = tabListItems[2];
      launchInvitedWorkstation.value = false;
      await store.dispatch(`${USER_STORE}/saveUserState`, updatedUser);
      await store.dispatch(`${USER_STORE}/initializeUserState`);
      await router.push({ name: DASHBOARD_ROUTE });
      // Reload the page after navigating to the dashboard
      window.location.reload();
    }, 5000);
  }
};

const formatDate = (dateString: any, format: string) => {
  const date = new Date(dateString);

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthsOfYear = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  switch (format) {
    case "fullDate": {
      const day = date.getUTCDate();
      const month = date.getUTCMonth();
      const year = date.getUTCFullYear();
      return `${day} ${monthsOfYear[month]}, ${year}`;
    }
    default: {
      throw new Error("Invalid format type");
    }
  }
};

const processedEmail = (email: any) => {
  if (email.startsWith("iknowa.alias.")) {
    return email.replace("iknowa.alias.", "");
  }
  return email;
};
const onClickGotoRatingPage = () => {
  router.push({ name: VIEW_RATING_ROUTE });
};
const isOpenServicePlanModal = ref(false);
const onClickOpenServicePlanModal = () => {
  isOpenServicePlanModal.value = !isOpenServicePlanModal.value;
};
const onServicePlanAction = (actionType: string) => {
  isOpenServicePlanModal.value = false;
  if (actionType === "workstation") {
    onClickToggleTradesWorkStation();
  }
};

const openPdf = () => {
  window.open(pdfFile, "_blank");
};

const workStationNotificationCount = computed(() => {
  const countObj: any = {};
  userNotifications.value.forEach((notification: any) => {
    countObj[notification?.userWorkstationId] =
      (countObj[notification?.userWorkstationId] || 0) + 1;
  });
  return countObj;
});
</script>
<style lang="scss" scoped>
@import "@/modules/dashboard/styles/propertyownerdashboard.scss";

.button-orange {
  :deep(.v-btn__content) {
    font-size: 12px;
  }

  :deep(.v-btn__append) {
    i {
      font-size: 14px;
    }
  }
}

.profile-logo {
  border-radius: 10px;
  background-color: #4a92ef;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ws-profile-logo {
  border-radius: 10px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.view-rating-box {
  padding: 12px 16px;
  background-color: #f3f3f6;
  border-radius: 6px;
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: fit-content;
  flex-wrap: wrap;
  .v-rating {
    :deep(.v-rating__wrapper) {
      .v-rating__item {
        .v-btn {
          width: auto;
          height: auto;
        }
      }
    }
  }
  p {
    @include fluidFont(16, 16, 1.2);
    font-weight: 700;
    letter-spacing: 0.12px;
    color: rgba($blueDark, 1);
  }
  .v-btn {
    padding: 5px 8px;
    min-height: auto;
    display: flex;
    text-transform: capitalize;
    height: auto;
  }
}

.button-purple {
  letter-spacing: 1.25px;
}

.course-section-card {
  background: linear-gradient(180deg, #d7d6ff 0%, #f4ebff 100%);
}

.ws-items-bg {
  // background: linear-gradient(180deg, #f3f3f6 0%, #fefefe 100%);
  background: white;
  border: 1px solid rgba($bordercolor, 1);
}
.action-modal .modal_wrapper {
  width: 100%;

  border-radius: 30px;
}

.action-modal .modal_wrapper {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(
      111.5deg,
      rgba(245, 250, 255, 0.3) 0%,
      rgba(211, 233, 255, 0.3) 100%
    ) !important;
}

.loading-dots {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  animation: loading-animation 3.5s infinite cubic-bezier(0.2, 0.6, 0.8, 0.2);
  position: relative;
  width: 160px;
  height: 160px;
  border-radius: 160px;
  background-color: $white;
  margin: 0 auto;
  box-shadow: 1px 1px 5px #f5f5f5;
}

@keyframes loading-animation {
  0% {
    transform: rotateZ(0deg) translate3d(0, 0, 0);
  }
  100% {
    transform: rotateZ(720deg) translate3d(0, 0, 0);
  }
}

.dot {
  border-radius: 50%;
  margin: 0 5px;
  display: inline-block;
  position: absolute;
}

.dot-red {
  background-color: $orange;
  width: 33px;
  height: 33px;
  top: 80px;
  left: 35px;
}

.dot-blue {
  background-color: $PrimaryBlue;
  width: 33px;
  height: 33px;
  border-radius: 40px;
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.dot-green {
  background-color: #c6d2f5;
  width: 33px;
  height: 33px;
  border-radius: 40px;
  top: 80px;
  right: 35px;
}

.switching-text {
  text-align: center;
  color: $PrimaryBlue;
  margin-top: 3rem;
}

.progress-bar {
  background-color: #ddd;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar-fill {
  background-color: #4caf50;
  height: 100%;
  width: 0;
  transition: width 5s linear;
}
</style>
