<template>
  <Card
    class="!tw-min-h-[440px] tw-p-4 tw-box-border !tw-border !tw-border-solid !tw-border-[#dfdfdf] !tw-bg-[#f8fbfd]"
  >
    <template #content>
      <main
        class="estimate-revision-summary tw-flex tw-flex-col tw-justify-between tw-gap-4 tw-items-start tw-w-full tw-h-full"
      >
        <div class="tw-flex tw-flex-col tw-gap-4 tw-items-start tw-w-full">
          <div
            class="tw-flex tw-items-center tw-w-full tw-justify-start tw-gap-2"
          >
            <Text variant="h6" whiteSpace="nowrap">
              {{
                isHistoricalProject ? "Cost Summary" : "Your Estimate Summary"
              }}</Text
            >
            <div>
              <v-tooltip
                max-width="242px"
                activator="parent"
                location="top"
                class="estimate_tooltip tw-cursor-pointer"
              >
                {{
                  isHistoricalProject ? "Cost Summary" : "Your Estimate Summary"
                }}
              </v-tooltip>

              <v-icon
                icon="mdi-information-slab-circle-outline mdi !tw-text-[16px]"
                color="#80829F"
              ></v-icon>
            </div>
          </div>

          <EstimateSummaryData
            :isReadOnly="true"
            :selectedEstimate="estimateResponseData"
          />
          <div
            class="estimate-revision-summary__phase-content tw-h-full tw-w-full tw-flex tw-flex-col tw-gap-4 tw-max-h-[370px] tw-overflow-auto"
          >
            <SmallPhaseItem
              class="historic-phase-content"
              v-for="(estimate, index) in estimateForm.projectStages"
              :estimate="estimate"
              :phaseIndex="index"
              :isHistoricalProject="isHistoricalProject"
              :key="index"
            />
          </div>
        </div>

        <div class="tw-flex tw-flex-col tw-gap-7 tw-w-full">
          <EstimateTotal
            :isChangeRequest="isChangeRequest"
            :isProperty="isProperty"
            :totalSubTotal="totalSubTotal"
            :totalVat="totalVat"
            :serviceCharges="serviceCharges"
            :isDIYProjectType="isDIYProjectType"
            :isShowSubmitBtn="false"
            @on-submit="onSubmitEstimate"
            @on-update-Estimation="updateProjectEstimation"
          />
        </div>
      </main>
    </template>
  </Card>
</template>
<script lang="ts" setup>
import { computed, reactive, ref } from "vue";
import { useStore } from "vuex";
import Card from "@/core/components/ui/general/Card.vue";
import Text from "@/core/components/ui/general/Text.vue";
import SiteVisitRequestDialog from "@/core/uni-components/SiteVisitRequestDialog.vue";
import EstimateSummaryData from "@/modules/jobs/components/estimates/EstimateSummaryData.vue";
import Button from "@/core/components/ui/general/Button.vue";
import EstimateTotal from "@/modules/jobs/components/estimates/EstimateTotal.vue";
import SelectDepositModal from "@/modules/jobs/components/modal/SelectDepositModal.vue";
import SubmitEstimateConfirmationModal from "@/modules/jobs/components/modal/SubmitEstimateConfirmationModal.vue";
import AcceptEstimateModal from "@/modules/jobs/components/modal/AcceptEstimateModal.vue";
import ServiceAgreementModal from "@/modules/jobs/components/modal/ServiceAgreementModal.vue";
import ConfirmJobModal from "@/modules/jobs/components/modal/ConfirmJobModal.vue";
import AddGrantModal from "@/modules/jobs/components/modal/AddGrantModal.vue";
import SmallPhaseItem from "@/modules/jobs/components/estimates/SmallPhaseItem.vue";
import InfoIcon from "@/core/components/icons/InfoIcon.vue";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import projectEstimationService from "@/core/services/project-estimation.service";
import {
  AgreeTermsConsumerDisclosure,
  EstimatePhaseStatus,
  EstimateTypeForDeposit,
} from "@/core/enums/estimateEnum";
import { ElMessage } from "element-plus";
import { USER_STORE } from "@/store/modules/user";
import { JOBS_STORE } from "@/store/modules/jobs";
import { WORKSTATION } from "@/store/modules/workstation";
import {
  getCurrentConTractorPhases,
  getSubContractorPhases,
} from "@/core/utils/common";
import FinalEstimateSubmitLoadingModal from "../modal/FinalEstimateSubmitLoadingModal.vue";
import ConfirmRaiseProject from "../modal/ConfirmRaiseProject.vue";
import { PropertyWorkflowTypeEnum } from "@/core/enums/PropertiesEnum";
import projectService from "@/core/services/project.service";
import { ProjectMetaDataStatusEnum } from "@/core/enums";
import { PROJECT_STORE } from "@/store/modules/project";
import { JOBS_ROUTE } from "../../routes";
import { useRouter } from "vue-router";
import RequestSiteVisitModal from "@/core/components/modals/projects/RequestSiteVisitModal.vue";
import RequestSiteVisitFormModal from "@/core/components/modals/projects/RequestSiteVisitFormModal.vue";
import SuccessSiteVisitRequestModal from "@/core/components/modals/projects/SuccessSiteVisitRequestModal.vue";
import EarnCreditsModal from "@/core/components/modals/projects/EarnCreditsModal.vue";

const store = useStore();
const router = useRouter();
const emits = defineEmits([]);
const props = defineProps({
  project: Object,
  projectEstimateId: Number,
  isProperty: Boolean,
  isHistoricalProject: {
    type: Boolean,
    default: false,
  },
  isDIYProjectType: {
    type: Boolean,
    default: false,
  },
  isChangeRequest: {
    type: Boolean,
    default: false,
  },
  formData: {
    type: Object,
  },
  estimateForm: {
    type: Object,
  },
  highlightChanges: {
    type: Boolean,
  },
  estimateResponseData: {
    type: Object,
  },
});

const user = computed(() => store.getters[`${USER_STORE}/user`]);
const grantLabel = computed(() => {
  return !props.formData.grantType ? "Add grant" : "Edit grant";
});

const isOpenRaiseProjectModal = ref(false);

// const formData = computed(
//   () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
// );
const isFormError = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getFromErrors`]
);
const IsFinalEstimateSubmitted = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/IsFinalEstimateSubmitted`]
);

const totalSubTotal = computed(() => {
  const sum = props.estimateForm.projectStages.reduce(
    (sum: number, phase: any) => {
      const roundedSubTotal = parseFloat(phase.subTotal);
      return sum + roundedSubTotal;
    },
    0
  );
  return Math.round(sum * 100) / 100;
});

const totalVat = computed(() => {
  if (props.isHistoricalProject) return 0;
  const sum = props.estimateForm.projectStages.reduce(
    (sum: number, phase: any, index: number) => {
      const roundedTotalVat = phase.subTotal * phase.vat;
      return sum + roundedTotalVat;
    },
    0
  );
  return Math.round(sum * 100) / 100;
});

const serviceCharges = computed(() => {
  if (props.isHistoricalProject) return 0;
  const initialProjectCharge = 5;
  const serviceFeePercentage = 0.03; // per phase 3 % charge
  let totalWithServiceFee = props.estimateForm.projectStages.reduce(
    (sum: number, phase: any) => {
      const roundedTotalWithServiceFee = phase.subTotal * serviceFeePercentage;
      return sum + roundedTotalWithServiceFee;
    },
    0
  );

  totalWithServiceFee += initialProjectCharge;
  return Math.round(totalWithServiceFee * 100) / 100;
});

// defineExpose({
//   // onSiteVisitRequest,
// });

const state = reactive({
  showSelectDepositModal: false,
  showSubmitEstimateConfirmationModal: false,
  showAcceptEstimateModal: false,
  showServiceAgreementModal: false,
  showConfirmJobModal: false,
  showAddGrandModal: false,
  showRequestSiteVisitModal: false,
});

const projectDetails = computed(
  () => store.getters[`${JOBS_STORE}/getJobDetails`]
);

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const onSubmitEstimate = async () => {
  const isValid = await store.dispatch(`${JOB_TEMPLATE_STORE}/validateForm`);
  if (
    isValid.flag &&
    !isFormError.value.startDate &&
    !isFormError.value.validationError
  ) {
    return;
  } else {
    if (isFormError.value.subTotalValidError) {
      ElMessage.error(`The calculated amount for phase is negative.`);
      return;
    }

    ElMessage.error(
      isFormError.value.validationError
        ? `validation failed`
        : isValid.errorMessage
    );
  }
};
const updateProjectEstimation = () => {
  return;
};
</script>
<style lang="scss" scoped>
.historic-phase-content {
  padding: 8px !important;
  background-color: rgba($white, 1) !important;
  border: 1px solid rgba($bordercolor, 1);
  border-radius: 8px !important;
}
</style>
