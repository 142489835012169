<template>
  <GeneralDialog
    :headerTitle="modalHeader.title"
    :headerDescription="modalHeader.description"
    @on-close="onCloseModal"
    @on-back="onBack"
    :showBackButton="true"
    width="480px"
  >
    <template #body>
      <div class="">
        <div
          class="tw-w-full tw-flex tw-flex-col tw-gap-4 tw-pb-3 tw-border-[#C5D2F2] tw-border-b tw-border-dashed tw-border-0"
        >
          <div class="tw-w-full">
            <Text variant="span" textWeight="400"> Project: </Text>
            <div class="tw-flex tw-gap-3 tw-items-center">
              <p
                class="tw-text-[14px] tw-font-normal tw-leading-[17.57px] tw-text-[#0C0F4A] tw-whitespace-nowrap tw-text-ellipsis tw-max-w-full tw-inline-block tw-overflow-hidden"
              >
                {{ projectData?.name }}
              </p>
            </div>
          </div>
          <div class="tw-flex tw-gap-1 tw-items-center tw-relative">
            <h5 class="tw-text-sm tw-font-semibold tw-leading-6 text-[#0C0F4A]">
              Permissions
            </h5>
            <span class="info__icon">
              <img src="../../../../assets/icons/info-grey.svg" alt="" />
              <div
                class="custom__tooltip__wrapper tw-inline-block tw-w-[220px] tw-max-w-full tw-bg-[#0C0F4A] tw-rounded-[4px] tw-px-[10px] tw-py-[5px] tw-absolute tw-left-[70px] tw-top-[-50px]"
              >
                <p
                  class="tw-text-[#fff] tw-text-[10px] tw-font-normal tw-tracking-[0.25px] tw-leading-[12.55px]"
                >
                  Grant permissions to invited contractors, allowing them to
                  view details from other phases within the estimate
                </p>
              </div>
            </span>
          </div>
        </div>

        <div class="tw-w-full">
          <div
            class="tw-w-full tw-flex tw-flex-col tw-gap-2 tw-py-4 tw-border-[#C5D2F2] tw-border-b tw-border-dashed tw-border-0"
            v-for="(contractor, idx) of getSelectedNetWorkUsers"
            :key="contractor.id"
          >
            <div class="tw-flex tw-justify-between tw-items-center">
              <div class="tw-flex tw-gap-2 tw-items-center">
                <div class="left_block">
                  <span
                    :class="
                      isUserWorkStationOnline(contractor?.id)
                        ? 'online_status'
                        : 'offline_status'
                    "
                  ></span>
                  <img
                    v-if="contractor?.receiverWorkstation?.profileImageUrl"
                    :src="contractor?.receiverWorkstation?.profileImageUrl"
                    alt=""
                  />

                  <div
                    v-else
                    class="tw-rounded-full tw-bg-[#0C0F4A] tw-w-9 tw-h-9 tw-text-white tw-flex tw-items-center tw-justify-center tw-text-[21px] tw-font-bold"
                  >
                    {{
                      getWSNameLogoChar(contractor?.receiverWorkstation?.name)
                    }}
                  </div>
                </div>
                <div>
                  <h5
                    class="tw-text-base tw-font-bold tw-leading-5 tw-text-[#0C0F4A]"
                  >
                    {{ contractor?.receiverWorkstation?.name }}
                  </h5>
                  <div class="subtitle">
                    <p
                      class="tw-text-xs tw-font-bold tw-leading-6 tw-text-[#0c0f4a80]"
                    >
                      {{
                        contractor?.receiverWorkstation.user
                          ?.userBusinessDetails?.type ===
                        BusinessDetailTypeEnum.LIMITED_COMPANY
                          ? "Limited Company"
                          : "Solo Trader"
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <v-icon
                class="cursor-pointer tw-text-[#0C0F4A] tw-text-base"
                icon="mdi-close"
                :size="16"
                @click="
                  removeSelectedContractor(contractor?.receiverWorkstation?.id)
                "
              ></v-icon>
            </div>

            <v-select
              class="c-select custom_selector"
              :items="permissionType"
              v-model="contractorPermissionData[idx]"
              item-title="type"
              item-value="id"
              placeholder="Select Permission"
              variant="outlined"
            ></v-select>
          </div>
          <div
            class="tw-w-full tw-flex tw-flex-col tw-gap-2 tw-py-4 tw-border-[#C5D2F2] tw-border-b tw-border-dashed tw-border-0"
          >
            <Text variant="span" textWeight="400"> Message (optional): </Text>
            <v-textarea
              row-height="25"
              rows="3"
              variant="outlined"
              auto-grow
              shaped
              v-model="messageContent"
              class=""
            ></v-textarea>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div>
        <span>{{ errorMessage }}</span>
      </div>
      <div class="tw-w-full tw-flex tw-justify-end">
        <v-btn
          @click="onClickSendInvitation"
          class="button button-orange md:tw-w-full"
          :disabled="isSendInviteDisable"
          >send invitation</v-btn
        >
      </div>
    </template>
  </GeneralDialog>
</template>
<script setup>
import { computed, onBeforeMount, ref } from "vue";

import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Text from "@/core/components/ui/general/Text.vue";
import { useStore } from "vuex";
import { TRADE_NETWORK } from "@/store/modules/trade-network";
import { WORKSTATION } from "@/store/modules/workstation";
import { BusinessDetailTypeEnum } from "@/core/enums/RolesEnum";
import estimationService from "@/core/services/estimation.service";
import { JOBS_STORE } from "@/store/modules/jobs";
import { permissionType } from "@/core/enums/estimateEnum";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
const emits = defineEmits(["on-close", "on-back", "on-send-invitation"]);
const props = defineProps({
  selectedContractorList: {
    type: Array,
  },
  projectData: {
    type: Object,
  },
});

const messageContent = ref("");
const errorMessage = ref(null);
const contractorPermissionData = ref([]);
const selectedContractorsId = ref(props.selectedContractorList);
const modalHeader = {
  title: "Send the contractor your estimate invitation",
  description:
    "Add a personalised message and confirm the project & phase you’re inviting the contractor to estimate.",
};

const onCloseModal = () => {
  emits("on-close");
};

const onBack = () => {
  emits("on-back", selectedContractorsId.value);
};
const store = useStore();
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const getUserNetworkConnectedList = computed(
  () => store.getters[`${TRADE_NETWORK}/getUserNetworkConnectedList`]
);

const formData = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
);
const onlineUser = computed(
  () => store.getters[`${WORKSTATION}/getUserOnlineWorkStations`]
);
const getSelectedNetWorkUsers = computed(() => {
  return getUserNetworkConnectedList.value.filter((connector) => {
    const match = selectedContractorsId.value.find((selectedConnector) => {
      return (
        selectedConnector.toContractorId === connector?.receiverWorkstation?.id
      );
    });
    return match;
  });
});
const removeSelectedContractor = (contractorId) => {
  selectedContractorsId.value = selectedContractorsId.value.filter(
    (contractor) => contractor.toContractorId !== contractorId
  );
  if (!selectedContractorsId.value.length) onBack();
};
const isSendInviteDisable = computed(
  () =>
    selectedContractorsId.value.length !== contractorPermissionData.value.length
);
const currentJobDetails = computed(
  () => store.getters[`${JOBS_STORE}/getJobDetails`]
);
const isUserWorkStationOnline = (wsId) => {
  const isExist = onlineUser.value?.find(
    (data) => data?.userWorkstations?.id === wsId
  );
  return isExist ? true : false;
};
const onClickSendInvitation = async () => {
  try {
    if (isSendInviteDisable.value) return;
    const inviteContractors = selectedContractorsId.value.map(
      (selectedConnector, idx) => ({
        toContractorId: selectedConnector.toContractorId,
        toUserId: selectedConnector.toUserId,
        permissionType: contractorPermissionData.value[idx],
      })
    );

    const response = await estimationService.inviteContractors(
      activeUserWorkstation.value.id,
      {
        inviteContractors,
        projectId: currentJobDetails.value?.id,
        description: messageContent.value,
        projectEstimateId: formData.value.id,
      }
    );
    emits("on-send-invitation");
  } catch (error) {
    if (error?.response?.status === 409)
      errorMessage.value = "Already send invitation";
  }
};

const getWSNameLogoChar = (WSName) => {
  if (WSName) {
    const initials = WSName.split(" ")
      .map((word) => word.charAt(0))
      .join("");
    return initials;
  }
  return "";
};
onBeforeMount(async () => {
  if (!getUserNetworkConnectedList.value.length)
    await store.dispatch(`${TRADE_NETWORK}/setUserNetworkConnections`, {
      activeUserWorkstationId: activeUserWorkstation.value?.id,
    });
});
</script>
<script>
export default {
  name: "ContractorInvitationModal",
};
</script>
<style lang="scss" scoped>
.custom_selector {
  :deep(.v-input__control) {
    .v-field {
      background-color: transparent;
      border: 1px solid rgba($buttonText, 0.4);
      border-radius: 8px;
      padding: 0;
      .v-field__field {
        .v-field__input {
          .v-select__selection {
            .v-select__selection-text {
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.4px;
            }
          }
          input {
            @include fluidFont(12, 12, 1.2);
            font-weight: 400;
            color: rgba($blueDark, 1);
            letter-spacing: 0.4px;

            &::placeholder {
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
              letter-spacing: 0.4px;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
.left_block {
  position: relative;
  .left_block__profile_img {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    background: rgba($blueDark, 1);
    overflow: hidden;
    color: rgba($white, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    @include fluidFont(21, 21, 1.2);
    font-weight: 700;
  }
  img {
    width: 36px;
    height: 36px;
    object-fit: cover;
    vertical-align: middle;
    border-radius: 100%;
  }
  .online_status {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: #3bc740;
    border: 1px solid rgba($white, 1);
    position: absolute;
    right: 0;
    top: 0;
  }
  .offline_status {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: rgba($errorRed, 1);
    border: 1px solid rgba($white, 1);
    position: absolute;
    right: 0;
    top: 0;
  }
}
.button-orange {
  @include respond(sm) {
    width: 100%;
  }
}

.custom__tooltip__wrapper {
  display: none;
}
.info__icon {
  cursor: pointer;
}
.info__icon:hover .custom__tooltip__wrapper {
  display: block;
}
.custom__tooltip__wrapper:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid rgba($blueDark, 1);
  bottom: -5px;
  transform: rotate(180deg);
}
</style>
