<template>
  <section class="tw-flex tw-flex-col tw-gap-6">
    <div class="tw-flex tw-flex-col tw-gap-2">
      <LabelWithValue
        :noBg="true"
        label="Project Estimate"
        :data="projectEstimateSubTotal"
      />
      <LabelWithValue :noBg="true" label="VAT" :data="totalVatFormat" />
      <LabelWithValue
        :noBg="true"
        label="Iknowa Service Fee"
        :data="serviceFormatCharges"
      >
        <LabelWithValue
          :noBg="true"
          label="Insurance Backed Gurantee"
          :data="ibgPolicyTotal"
        ></LabelWithValue>
        <template #postfix>
          <ToolTip
            description="This helps us run our platform and offer services like 24/7 support. £5 + 3% added to each estimate."
          />
        </template>
      </LabelWithValue>
      <LabelWithValue
        v-if="grantAmount"
        :noBg="true"
        :label="grantName"
        :data="grantFormatAmount"
      ></LabelWithValue>
      <v-divider :thickness="2"></v-divider>
      <LabelWithValue
        :noBg="true"
        :allFontBold="true"
        label="Total Project Estimate"
        :data="customerTotal"
      />
    </div>
  </section>
</template>
<script setup>
import LabelWithValue from "@/core/components/common/LabelWithValue.vue";
import ToolTip from "@/core/components/common/ToolTip.vue";
import InfoIcon from "@/core/components/icons/InfoIcon.vue";
import currencyFilter from "@/filters/currency.filter";
import { computed } from "vue";

const emits = defineEmits(["on-submit"]);
const props = defineProps({
  totalSubTotal: {
    type: Number,
  },
  totalVat: {
    type: Number,
  },
  serviceCharges: {
    type: Number,
  },
  grantName: {
    type: String || null,
  },
  grantAmount: {
    type: Number || null,
  },
  ibgPolicyTotal: {
    type: Number || null,
  },
});
const onSubmitEstimate = () => {
  emits("on-submit");
};

const projectEstimateSubTotal = computed(() => {
  return formatCurrency(props.totalSubTotal);
});

const formatCurrency = (value, flag = false) => {
  if (flag) return `- ${currencyFilter.formatToCurrency(value)}`;
  return currencyFilter.formatToCurrency(value);
};
const customerTotal = computed(() => {
  let total = props.totalSubTotal + props.totalVat + props.serviceCharges;
  if (props.grantAmount) {
    total -= props.grantAmount;
  }
  const totalValue = Math.round(parseFloat(total) * 100) / 100;
  return currencyFilter.formatToCurrency(totalValue);
});
const grantFormatAmount = computed(() => {
  return currencyFilter.formatToCurrency(props.grantAmount);
});
const serviceFormatCharges = computed(() => {
  return currencyFilter.formatToCurrency(props.serviceCharges);
});
const totalVatFormat = computed(() => {
  return currencyFilter.formatToCurrency(props.totalVat);
});
</script>
<script>
export default {
  name: "EstimateReceivedTotal",
};
</script>
<style lang="scss" scoped></style>
