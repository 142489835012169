<template>
  <Card
    class="!tw-min-h-[440px] tw-p-4 tw-box-border !tw-border !tw-border-solid !tw-border-[#dfdfdf] !tw-bg-[#f8fbfd]"
  >
    <template #content>
      <main
        class="estimate-revision-summary tw-flex tw-flex-col tw-justify-between tw-gap-4 tw-items-start tw-w-full tw-h-full"
      >
        <div class="tw-flex tw-flex-col tw-gap-4 tw-items-start tw-w-full">
          <div
            class="tw-flex tw-items-center tw-w-full tw-justify-between tw-gap-2"
          >
            <Text variant="h6" whiteSpace="nowrap">
              {{
                isHistoricalProject ? "Cost Summary" : "Your Estimate Summary"
              }}</Text
            >
            <div>
              <v-tooltip
                max-width="242px"
                activator="parent"
                location="top"
                class="estimate_tooltip tw-cursor-pointer"
              >
                {{
                  isHistoricalProject ? "Cost Summary" : "Your Estimate Summary"
                }}
              </v-tooltip>

              <v-icon
                icon="mdi-information !tw-text-[16px]"
                color="#0C0F4A80"
              ></v-icon>
            </div>
          </div>
          <EstimateSummaryData :isHistoricalProject="isHistoricalProject" />
          <Button
            v-if="IsFinalEstimateSubmitted && project?.siteVisitStatus"
            variant="secondary"
            label="Request site visit"
            @click="onRequestSiteVisit"
          />
          <div
            class="estimate-revision-summary__phase-content tw-h-full tw-w-full tw-flex tw-flex-col tw-gap-4 tw-max-h-[370px] tw-overflow-auto"
          >
            <template
              v-if="
                estimateForm?.estimationIBGData?.estimationIbgPolicies?.length
              "
            >
              <div class="estimate_summary__info__list__item">
                <div class="items_list">
                  <div class="item">Insurance Backed Guarantee</div>
                  <div class="items_cost">
                    <div class="number_items">
                      {{
                        estimateForm?.estimationIBGData?.estimationIbgPolicies
                          ?.length
                      }}
                      <span>Policy</span>
                    </div>
                    <div>|</div>
                    <div>
                      {{ currencyFilter.formatToCurrency(IBGTotalCost) }}
                    </div>
                  </div>
                </div>
              </div>
              <template
                v-for="(policy, policyIndex) of estimateForm?.estimationIBGData
                  ?.estimationIbgPolicies"
                :key="policy.id"
              >
                <div class="estimate_summary__info__list__item">
                  <div
                    v-for="(item, index) in policy?.phases"
                    :key="index"
                    class="items_list"
                  >
                    <div class="item">
                      {{
                        item?.stageType === estimate.LABOUR
                          ? "Phase"
                          : "Materials"
                      }}
                      {{ index + 1 }} - Policy {{ policyIndex + 1 }}
                    </div>
                    <div class="items_cost">
                      <div class="number_items">
                        {{ item?.projectStageTasks?.length || "0" }}
                        <span>Policy</span>
                      </div>
                      <div>|</div>
                      <div>
                        {{ currencyFilter.formatToCurrency(item?.subTotal) }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <SmallPhaseItem
                  v-for="(estimate, index) in policy.phases"
                  :estimate="estimate"
                  :policyIndex="policyIndex"
                  :phaseIndex="index"
                  :isHistoricalProject="isHistoricalProject"
                  :key="index"
                  class="historic-phase-content"
                /> -->
              </template>
            </template>
            <div class="estimate_summary__info__list__item">
              <div
                v-for="(phase, index) in estimateForm.projectStages"
                :key="index"
                class="items_list"
              >
                <div class="item">
                  {{
                    phase?.stageType === estimate.LABOUR ? "phase" : "Materials"
                  }}
                  {{ index + 1 }}
                </div>
                <div class="items_cost">
                  <div class="number_items">
                    {{ phase?.projectStageTasks?.length || "0" }}
                    <span>items</span>
                  </div>
                  <div>|</div>
                  <div>
                    {{ currencyFilter.formatToCurrency(phase?.subTotal) }}
                  </div>
                </div>
              </div>
            </div>
            <!-- <SmallPhaseItem -->
            <!-- v-for="(estimate, index) in estimateForm.projectStages" -->
            <!-- :estimate="estimate" -->
            <!-- :phaseIndex="index" -->
            <!-- :isHistoricalProject="isHistoricalProject" -->
            <!-- :key="index" -->
            <!-- class="historic-phase-content" -->
            <!-- /> -->
          </div>
        </div>

        <!-- <div
          class="ibg_wrapper tw-border tw-border-solid tw-border-lightGrey tw-rounded-lg tw-p-2 tw-bg-lightBlue/[0.06] tw-w-full tw-max-w-full tw-text-left"
          v-if="estimateForm?.estimationIBGData"
        >
          <div
            class="ibg_wrapper__title tw-text-xs tw-font-bold tw-text-darkBlue tw-leading-6"
          >
            Insurance Backed Guarantee
          </div>
          <div
            class="ibg_wrapper__content tw-flex tw-justify-between tw-items-center tw-pb-2.5 tw-border-0 tw-border-b tw-border-dashed tw-border-lightGrey"
            v-for="policy of estimateForm?.estimationIBGData
              ?.estimationIbgPolicies || []"
            :key="policy?.id"
          >
            <div class="left_block">
              <p
                class="tw-text-xs tw-font-normal tw-text-darkBlue tw-leading-6"
              >
                {{ policy?.policyType || "-" }}
              </p>
              <span
                class="tw-text-xs tw-font-bold tw-text-darkBlue tw-leading-6"
                >{{
                  currencyFilter.formatToCurrency(policy.totalPolicyCost || 0)
                }}</span
              >
            </div>
            <div
                class="right_block tw-bg-white tw-flex tw-items-center tw-justify-center tw-border tw-border-solid tw-rounded-md tw-border-lightGrey tw-px-1.5"
              >
                <v-btn
                  class="decrement tw-w-2.5 !tw-p-0 !tw-h-auto !tw-min-w-[auto] !tw-shadow-none"
                  >-</v-btn
                >
                <TextField
                  v-model="policy.numberOfPolicy"
                  disabled
                  class="range_input !tw-min-w-[auto] !tw-p-0"
                />
                <v-btn
                  class="increment tw-w-2.5 !tw-p-0 !tw-h-auto !tw-min-w-[auto] !tw-shadow-none"
                  >+</v-btn
                >
              </div> 
         </div> 
         </div> -->

        <div class="tw-flex tw-flex-col tw-gap-7 tw-w-full">
          <Button
            v-if="!isDisabled"
            variant="secondary"
            :label="grantLabel"
            @click="onOpenAddGrantModal(isChangeRequest)"
          />
          <EstimateTotal
            :isChangeRequest="isChangeRequest"
            :isHighlightChange="isHighlightChange"
            :isProperty="isProperty"
            @on-submit="onSubmitEstimate"
            @on-route-to-schedule="onRouteToSchedule"
            @on-preview-estimate="onPreviewEstimateSubmit"
            :totalSubTotal="totalSubTotal"
            :totalVat="totalVat"
            :serviceCharges="serviceCharges"
            :isDIYProjectType="isDIYProjectType"
            :insuranceBackedTotal="IBGTotalCost"
            @on-update-Estimation="updateProjectEstimation"
          />
        </div>

        <!-- MODALS -->
        <SelectDepositModal
          v-if="state.showSelectDepositModal"
          :sumTotal="true"
          :estimate="estimateForm.projectStages"
          :totalSubTotal="totalSubTotal"
          :totalVat="totalVat"
          :serviceCharges="serviceCharges"
          @on-confirm="onConfirmSelectDepositModal"
          @on-close="onCloseSelectDepositModal"
        />
        <SubmitEstimateConfirmationModal
          v-if="state.showSubmitEstimateConfirmationModal"
          @on-save="onSubmitEstimateConfirmationModal"
          @on-close="onCloseSubmitEstimateConfirmationModal"
        />
        <AcceptEstimateModal
          v-if="state.showAcceptEstimateModal"
          @on-close="onCloseAcceptEstimateModal"
        />
        <ServiceAgreementModal
          v-if="state.showServiceAgreementModal"
          type="contactor"
          :project="project"
          :projectEstimate="projectEstimate"
          @on-confirm="onConfirmServiceAgreementModal"
          @on-close="onCloseServiceAgreementModal"
        />
        <ConfirmJobModal
          v-if="state.showConfirmJobModal"
          @on-close="onCloseConfirmJobModal"
        />

        <!-- v-if="state.showAddGrandModal" -->
        <AddGrantModal
          v-if="state.showAddGrandModal"
          :totalSubTotal="totalSubTotal"
          :totalVat="totalVat"
          :serviceCharges="serviceCharges"
          :isDIYProjectType="isDIYProjectType"
          :projectEstimate="projectEstimate"
          @on-close="onCloseAddGrantModal"
          @on-save-project-estimation="updateProjectEstimation"
        />
        <AddUpdateGrantModal
          v-if="state.showAddUpdateGrandModal"
          :totalSubTotal="totalSubTotal"
          :totalVat="totalVat"
          :serviceCharges="serviceCharges"
          :isDIYProjectType="isDIYProjectType"
          :projectEstimate="projectEstimate"
          @on-close="onCloseAddUpdateGrantModal"
          @on-save-project-estimation="updateProjectEstimation"
        />
        <SiteVisitRequestDialog
          v-if="state.showRequestSiteVisitModal"
          :projectEstimateId="projectEstimateId"
          :projectId="project?.id"
          @on-close="onCloseRequestSiteVisitModal"
        />
        <RequestSiteVisitModal
          v-if="isShowSiteVisitRequestModal"
          @on-close="onCloseAllSiteVisitModal"
          @on-request-a-site-visit="onOpenSiteVisitRequestFormModal"
        />

        <RequestSiteVisitFormModal
          v-if="isShowSiteVisitRequestFormModal"
          @on-close="onCloseAllSiteVisitModal"
          @on-back="onOpenSiteRequestVisitModal"
          @on-submit-success="onOpenSuccessSiteVisitRequestModal"
        />

        <SuccessSiteVisitRequestModal
          v-if="isShowSuccessSiteVisitRequestModal"
          @on-close="onCloseAllSiteVisitModal"
          @on-continue="onOpenSiteVisitCreditsModal"
        />

        <EarnCreditsModal
          v-if="isShowSiteVisitCreditsModal"
          @on-close="onCloseAllSiteVisitModal"
        />

        <!-- loading -->
        <FinalEstimateSubmitLoadingModal
          v-if="finalEstimateLoadingModal.visible"
          :step="finalEstimateLoadingModal.step"
          @onCancel="onFinalEstimateSubmitLoadingCancel"
        />

        <ConfirmRaiseProject
          v-if="isOpenRaiseProjectModal"
          @on-close="onCloseRaiseProjectModal"
          @on-confirm="onConfirmRaiseProjectModal"
        />
      </main>
    </template>
  </Card>
</template>
<script lang="ts" setup>
import { computed, reactive, ref } from "vue";
import { useStore } from "vuex";

import Card from "@/core/components/ui/general/Card.vue";
import Text from "@/core/components/ui/general/Text.vue";
import SiteVisitRequestDialog from "@/core/uni-components/SiteVisitRequestDialog.vue";
import EstimateSummaryData from "@/modules/jobs/components/estimates/EstimateSummaryData.vue";
import Button from "@/core/components/ui/general/Button.vue";
import EstimateTotal from "@/modules/jobs/components/estimates/EstimateTotal.vue";
import SelectDepositModal from "@/modules/jobs/components/modal/SelectDepositModal.vue";
import SubmitEstimateConfirmationModal from "@/modules/jobs/components/modal/SubmitEstimateConfirmationModal.vue";
import AcceptEstimateModal from "@/modules/jobs/components/modal/AcceptEstimateModal.vue";
import ServiceAgreementModal from "@/modules/jobs/components/modal/ServiceAgreementModal.vue";
import ConfirmJobModal from "@/modules/jobs/components/modal/ConfirmJobModal.vue";
import AddGrantModal from "@/modules/jobs/components/modal/AddGrantModal.vue";
import AddUpdateGrantModal from "@/modules/jobs/components/modal/AddUpdateGrantModal.vue";
import SmallPhaseItem from "@/modules/jobs/components/estimates/SmallPhaseItem.vue";
import InfoIcon from "@/core/components/icons/InfoIcon.vue";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import projectEstimationService from "@/core/services/project-estimation.service";
import {
  AgreeTermsConsumerDisclosure,
  EstimatePhaseStatus,
  EstimatePhaseType,
  EstimateTypeForDeposit,
} from "@/core/enums/estimateEnum";
import { ElMessage } from "element-plus";
import { USER_STORE } from "@/store/modules/user";
import { JOBS_STORE } from "@/store/modules/jobs";
import { WORKSTATION } from "@/store/modules/workstation";
import {
  getCurrentConTractorPhases,
  getSubContractorPhases,
} from "@/core/utils/common";
import FinalEstimateSubmitLoadingModal from "../modal/FinalEstimateSubmitLoadingModal.vue";
import ConfirmRaiseProject from "../modal/ConfirmRaiseProject.vue";
import { PropertyWorkflowTypeEnum } from "@/core/enums/PropertiesEnum";
import projectService from "@/core/services/project.service";
import { ProjectMetaDataStatusEnum } from "@/core/enums";
import { PROJECT_STORE } from "@/store/modules/project";
import { JOBS_ROUTE } from "../../routes";
import { useRouter } from "vue-router";
import RequestSiteVisitModal from "@/core/components/modals/projects/RequestSiteVisitModal.vue";
import RequestSiteVisitFormModal from "@/core/components/modals/projects/RequestSiteVisitFormModal.vue";
import SuccessSiteVisitRequestModal from "@/core/components/modals/projects/SuccessSiteVisitRequestModal.vue";
import EarnCreditsModal from "@/core/components/modals/projects/EarnCreditsModal.vue";
import TextField from "@/core/components/common/TextField.vue";
import currencyFilter from "@/filters/currency.filter";
import estimate from "@/core/constants/estimate";

const store = useStore();
const router = useRouter();

const emits = defineEmits([
  "on-save-estimation",
  "onFinalSave",
  "on-route-to-schedule",
  "on-route-to-preview-estimate-changes",
]);

const isShowSiteVisitRequestModal = ref(false);
const isShowSiteVisitRequestFormModal = ref(false);
const isShowSuccessSiteVisitRequestModal = ref(false);
const isShowSiteVisitCreditsModal = ref(false);

const estimateForm = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/estimateForm`]
);
const user = computed(() => store.getters[`${USER_STORE}/user`]);
const grantLabel = computed(() => {
  return !formData.value.grantType ? "Add grants & discounts" : "Edit grant";
});
const workFlowType = computed(
  () => store.getters[`${JOBS_STORE}/getWorkFlowType`]
);
const isOpenRaiseProjectModal = ref(false);
const onCloseRaiseProjectModal = () => {
  isOpenRaiseProjectModal.value = false;
};
const onConfirmRaiseProjectModal = () => {
  isOpenRaiseProjectModal.value = false;
  state.showServiceAgreementModal = true;
};
const formData = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/formData`]
);
const isFormError = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getFromErrors`]
);
const IsFinalEstimateSubmitted = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/IsFinalEstimateSubmitted`]
);

const IBGTotalCost = computed(() => {
  return estimateForm?.value.estimationIBGData?.estimationIbgPolicies?.reduce(
    (sum: any, phase: any) => sum + (parseFloat(phase?.totalPolicyCost) || 0),
    0
  );
});

const ibgPolicyPhasesTotal = computed(() => {
  return (
    estimateForm?.value.estimationIBGData?.estimationIbgPolicies
      ?.flatMap((phase: any) => phase?.phases || [])
      ?.reduce(
        (sum: any, item: any) => sum + (parseFloat(item.subTotal) || 0),
        0
      ) || 0
  );
});

const totalSubTotal = computed(() => {
  const sum = estimateForm.value.projectStages.reduce(
    (sum: number, phase: any) => {
      const roundedSubTotal = parseFloat(phase.subTotal);
      return sum + roundedSubTotal;
    },
    0
  );

  return Math.round(sum * 100) / 100 + ibgPolicyPhasesTotal.value;
});

const depositModalData = ref(null) as any;
const finalEstimateLoadingModal = reactive({
  visible: false,
  step: 0,
});

const isLockTemplateModifiedChecker = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/lockTemplateModifiedChecker`]
);

const totalVat = computed(() => {
  if (props.isHistoricalProject) return 0;
  let sum = estimateForm.value.projectStages.reduce(
    (sum: number, phase: any, index: number) => {
      const roundedTotalVat = phase.subTotal * phase.vat;
      return sum + roundedTotalVat;
    },
    0
  );

  let ibgVat =
    estimateForm?.value.estimationIBGData?.estimationIbgPolicies
      ?.flatMap((phase: any) => phase?.phases || [])
      ?.reduce(
        (sum: any, item: any) =>
          sum + (parseFloat(item.subTotal) || 0) * (parseFloat(item.vat) || 0),
        0
      ) || 0;

  return Math.round(sum * 100) / 100 + ibgVat;
});

const serviceCharges = computed(() => {
  if (props.isHistoricalProject) return 0;
  const initialProjectCharge = 5;
  const serviceFeePercentage = 0.03; // per phase 3 % charge
  let totalWithServiceFee = estimateForm.value.projectStages.reduce(
    (sum: number, phase: any) => {
      const roundedTotalWithServiceFee = !parseFloat(phase.updatedSubTotal)
        ? phase.subTotal * serviceFeePercentage
        : phase.updatedSubTotal * serviceFeePercentage;
      return sum + roundedTotalWithServiceFee;
    },
    0
  );

  let ibgServiceCharge =
    estimateForm?.value?.estimationIBGData?.estimationIbgPolicies
      ?.flatMap((phase: any) => phase?.phases || [])
      ?.reduce((sum: any, item: any) => {
        const subTotal = parseFloat(item.subTotal) || 0;
        return sum + subTotal * serviceFeePercentage;
      }, 0) || 0;
  totalWithServiceFee += initialProjectCharge + ibgServiceCharge;
  return Math.round(totalWithServiceFee * 100) / 100;
});

const projectEstimate = computed(() => {
  return {
    ...formData.value,
    projectEstimateCreator: {
      ...user.value,
      workStation: activeUserWorkstation.value,
    },

    projectStages: estimateForm.value?.projectStages,
  };
});

const onRouteToSchedule = async () => {
  const isValid = await store.dispatch(`${JOB_TEMPLATE_STORE}/validateForm`);
  if (
    isValid.flag &&
    !isFormError.value.startDate &&
    !isFormError.value.validationError
  ) {
    const isAccepted = checkIfAllAccepted();

    if (isAccepted.length === 0) {
      const hasSubmiited = await hasSubContractorsSubmitted();
      if (hasSubmiited) {
        emits("on-save-estimation");
        emits("on-route-to-schedule");
      } else {
        ElMessage.error("Some of the sub-contractors have not submitted yet");
      }
    } else {
      ElMessage.error("please accept or reject the pending phases");
    }
  } else {
    if (isFormError.value.subTotalValidError) {
      ElMessage.error(`The calculated amount for phase is negative.`);
      return;
    }

    ElMessage.error(
      isFormError.value.validationError
        ? `validation failed`
        : isValid.errorMessage
    );
  }
};

const onPreviewEstimateSubmit = async () => {
  const isValid = await store.dispatch(`${JOB_TEMPLATE_STORE}/validateForm`);
  if (
    isValid.flag &&
    !isFormError.value.startDate &&
    !isFormError.value.validationError
  ) {
    emits("on-route-to-preview-estimate-changes");
  } else {
    if (isFormError.value.subTotalValidError) {
      ElMessage.error(`The calculated amount for phase is negative.`);
      return;
    }

    ElMessage.error(
      isFormError.value.validationError
        ? `validation failed`
        : isValid.errorMessage
    );
  }
};

const submitUpdatedEstimate = async () => {
  try {
    const initialEstimateData =
      getDefaultEstimateDetails.value?.projectStages.map((stage: any) => {
        return {
          ...stage,
          user: {
            id: stage.user.id,
          },
          workStation: {
            id: stage.workStation.id,
          },
        };
      });
    if (!formData.value?.id) {
      return;
    }
    const newEstimate = JSON.stringify(estimateForm.value);
    const oldEstimate = JSON.stringify({
      projectStages: initialEstimateData,
    });

    const oldDbEstimate = JSON.stringify(
      projectEstimatePayload({
        projectStages: initialEstimateData,
      })
    );
    const updatedDbEstimate = JSON.stringify(
      projectEstimatePayload(estimateForm.value)
    );

    const payloadData = {
      estimateId: formData.value.id,
      payload: {
        oldEstimate: oldEstimate,
        updatedEstimate: newEstimate,
        oldDbEstimate: oldDbEstimate,
        updatedDbEstimate: updatedDbEstimate,
      },
    };

    console.log("payloadData :>> ", payloadData);
    await store.dispatch(
      `${JOB_TEMPLATE_STORE}/updateEstimateRequest`,
      payloadData
    );
    // emits("on-route-to-schedule");
  } catch (error) {
    console.log("error :>> ", error);
  }
};

const projectEstimatePayload = (estimateForm: any) => {
  let estimationPhaseData = [] as any;
  if (estimateForm) {
    estimateForm.projectStages.map((estimate, index) => {
      const estimateRecord = {
        id: estimate.id,
        name: estimate.name,
        phaseType: estimate.stageType === "labour" ? 1 : 2,
        durationType: estimate.durationType,
        phaseStartDate: estimate.startDate,
        phaseEndDate: estimate.endDate,
        vat: estimate.vat,
        subTotal: !estimate.subTotal
          ? "0"
          : typeof estimate.subTotal === "number"
          ? estimate.subTotal.toString()
          : estimate.subTotal,
        phaseOrder: estimate.phaseOrder,
        estimationPhaseTasks: [],
        estimationPhaseProperties: estimate.estimationPhaseProperties,
        color: estimate.color,
        user: estimate?.user,
        workStation: estimate?.workStation,
        grantAmount: estimate?.grantAmount,
        isPercentage: estimate?.isPercentage,
        isGranted: estimate?.isGranted,
      };

      if (estimate?.projectStageTasks && estimate?.projectStageTasks?.length) {
        estimate?.projectStageTasks.map((task) => {
          const estimateTask = {
            id: task.id,
            name: task.name,
            phaseTaskStartDate: task.startDate,
            phaseTaskEndDate: task.endDate,
            durationType: task.durationType,
            taskCost: !task.taskCost
              ? "0"
              : typeof task.taskCost === "number"
              ? task.taskCost.toString()
              : task.taskCost,
            estimationPhaseTasksProperties: task.estimationPhaseTasksProperties,
            materialUnit: task.materialUnit?.toString(),
          };

          estimateRecord?.estimationPhaseTasks.push(estimateTask);
        });
      }
      estimationPhaseData.push(estimateRecord);
    });
  }

  return estimationPhaseData;
};
const onOpenSiteRequestVisitModal = () => {
  isShowSiteVisitRequestModal.value = true;
  isShowSiteVisitRequestFormModal.value = false;
  isShowSuccessSiteVisitRequestModal.value = false;
  isShowSiteVisitCreditsModal.value = false;
};
const onOpenSiteVisitRequestFormModal = () => {
  isShowSiteVisitRequestModal.value = false;
  isShowSiteVisitRequestFormModal.value = true;
  isShowSuccessSiteVisitRequestModal.value = false;
  isShowSiteVisitCreditsModal.value = false;
};
const onOpenSuccessSiteVisitRequestModal = () => {
  isShowSiteVisitRequestModal.value = false;
  isShowSiteVisitRequestFormModal.value = false;
  isShowSuccessSiteVisitRequestModal.value = true;
  isShowSiteVisitCreditsModal.value = false;
};
const onOpenSiteVisitCreditsModal = () => {
  isShowSiteVisitRequestModal.value = false;
  isShowSiteVisitRequestFormModal.value = false;
  isShowSuccessSiteVisitRequestModal.value = false;
  isShowSiteVisitCreditsModal.value = true;
};

const onCloseAllSiteVisitModal = () => {
  isShowSiteVisitRequestModal.value = false;
  isShowSiteVisitRequestFormModal.value = false;
  isShowSuccessSiteVisitRequestModal.value = false;
  isShowSiteVisitCreditsModal.value = false;
};

const onRequestSiteVisit = () => {
  if (IsFinalEstimateSubmitted.value && props.project?.siteVisitStatus) {
    onOpenSiteRequestVisitModal();
  }
};

const onSiteVisitRequest = () => {
  if (IsFinalEstimateSubmitted.value && props.project?.siteVisitStatus) {
    state.showRequestSiteVisitModal = true;
  }
};

defineExpose({
  // onSiteVisitRequest,
  onRequestSiteVisit,
});

const props = defineProps({
  project: Object,
  projectEstimateId: Number,
  isProperty: Boolean,
  isHistoricalProject: {
    type: Boolean,
    default: false,
  },
  isDIYProjectType: {
    type: Boolean,
    default: false,
  },
  isChangeRequest: {
    type: Boolean,
    default: false,
  },
  isHighlightChange: {
    type: Boolean,
    default: false,
  },
});
const state = reactive({
  showSelectDepositModal: false,
  showSubmitEstimateConfirmationModal: false,
  showAcceptEstimateModal: false,
  showServiceAgreementModal: false,
  showConfirmJobModal: false,
  showAddGrandModal: false,
  showAddUpdateGrandModal: false,
  showRequestSiteVisitModal: false,
});

const hasSubContractorsSubmitted = async () => {
  const subContractors = [] as any;
  const invitedContractorsList =
    store.getters[`${JOB_TEMPLATE_STORE}/invitedContractorsList`];
  invitedContractorsList.map((con: any) => {
    if (con.fromContractorId == activeUserWorkstation.value?.id) {
      subContractors.push(con);
    }
  });
  return !subContractors.some(
    (el: any) =>
      el.agreeTermsConsumerDisclosure == AgreeTermsConsumerDisclosure.NOT_ADDED
  );
};

const onSubmitEstimate = async () => {
  const isValid = await store.dispatch(`${JOB_TEMPLATE_STORE}/validateForm`);
  if (
    isValid.flag &&
    !isFormError.value.startDate &&
    !isFormError.value.validationError
  ) {
    const isAccepted = checkIfAllAccepted();

    if (isAccepted.length === 0) {
      const hasSubmiited = await hasSubContractorsSubmitted();
      if (hasSubmiited) {
        if (isSubContractor.value) {
          state.showSubmitEstimateConfirmationModal = true;
        } else {
          //Save Estimation
          state.showSelectDepositModal = true;
          emits("on-save-estimation");
        }
      } else {
        ElMessage.error("Some of the sub-contractors have not submitted yet");
      }
    } else {
      ElMessage.error("please accept or reject the pending phases");
    }
  } else {
    if (isFormError.value.subTotalValidError) {
      ElMessage.error(`The calculated amount for phase is negative.`);
      return;
    }

    ElMessage.error(
      isFormError.value.validationError
        ? `validation failed`
        : isValid.errorMessage
    );
  }
};

const isSubContractor = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getIsSubContractor`]
);

const onCloseSelectDepositModal = () => {
  state.showSelectDepositModal = false;
};

const projectEstimateInvitation = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getProjectEstimationInvitation`]
);
const getDefaultEstimateDetails = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/getDefaultEstimateDetails`]
);

const onConfirmSelectDepositModal = async (payload: any) => {
  if (payload) {
    depositModalData.value = payload;
    state.showSelectDepositModal = false;

    if (
      workFlowType.value &&
      workFlowType.value === PropertyWorkflowTypeEnum.RAISE_CLIENT
    ) {
      isOpenRaiseProjectModal.value = true;
    } else {
      state.showSubmitEstimateConfirmationModal = true;
    }
  }
};

const onSubmitEstimateConfirmationModal = () => {
  state.showSubmitEstimateConfirmationModal = false;
  state.showServiceAgreementModal = true;
};

const onCloseSubmitEstimateConfirmationModal = () => {
  state.showSubmitEstimateConfirmationModal = false;
};

const onCloseAcceptEstimateModal = () => {
  state.showAcceptEstimateModal = false;
};

const onCloseServiceAgreementModal = () => {
  state.showServiceAgreementModal = false;
};

const onConfirmServiceAgreementModal = async () => {
  const isSubmitProject = await submitEstimate();
  if (isSubmitProject) {
    state.showServiceAgreementModal = false;
  }
};

const onCloseConfirmJobModal = () => {
  state.showConfirmJobModal = false;
};

const onCloseAddGrantModal = () => {
  state.showAddGrandModal = false;
};

const onCloseAddUpdateGrantModal = () => {
  state.showAddUpdateGrandModal = false;
};

const onOpenAddGrantModal = (ischangerequest: boolean) => {
  if (ischangerequest) {
    state.showAddUpdateGrandModal = true;
  } else {
    state.showAddGrandModal = true;
  }
};
const onCloseRequestSiteVisitModal = () => {
  state.showRequestSiteVisitModal = false;
};

const updateProjectEstimation = (value: any) => {
  if (value == "update") {
    emits("on-save-estimation", "update");
  } else {
    emits("on-save-estimation");
  }
};
const projectDetails = computed(
  () => store.getters[`${JOBS_STORE}/getJobDetails`]
);

const isEstimateSubmit = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/isEstimateSubmit`]
);
const isUserHasEstimateEditAccess = computed(
  () => store.getters[`${JOB_TEMPLATE_STORE}/isUserHasEstimateEditAccess`]
);
const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const isAddAnyPhases = computed(
  () =>
    !!(
      estimateForm.value?.estimationIBGData?.estimationIbgPolicies?.length ||
      estimateForm.value?.projectStages?.length
    )
);
const isDisabled = computed(
  () =>
    isLockTemplateModifiedChecker.value ||
    isEstimateSubmit.value ||
    !isUserHasEstimateEditAccess.value ||
    !isAddAnyPhases.value
);

const checkIfAllAccepted = () => {
  const invitedContractorsList =
    store.getters[`${JOB_TEMPLATE_STORE}/invitedContractorsList`];
  const setTotalPhases = store.getters[`${JOB_TEMPLATE_STORE}/getTotalPhases`];

  const subCon = getSubContractorPhases(
    activeUserWorkstation.value?.id,
    invitedContractorsList,
    setTotalPhases
  );
  const currCon = getCurrentConTractorPhases(
    activeUserWorkstation.value?.id,
    setTotalPhases
  );

  const subFinal = subCon.filter((el: any) => {
    let acceptedBy = null;
    if (el.acceptedBy == null) {
      acceptedBy = [];
    } else {
      if (Array.isArray(el.acceptedBy)) {
        acceptedBy = el.acceptedBy;
      } else {
        if (
          typeof el.acceptedBy == "string" &&
          JSON.parse(el.acceptedBy) &&
          Array.isArray(JSON.parse(el.acceptedBy))
        ) {
          acceptedBy = JSON.parse(el.acceptedBy);
        } else {
          acceptedBy = [];
        }
      }
    }

    if (
      [EstimatePhaseStatus.PENDING, EstimatePhaseStatus.WAITING].includes(
        el.phaseStatus
      )
    ) {
      return true;
    }

    if (
      [EstimatePhaseStatus.ACCEPTED].includes(el.phaseStatus) &&
      !acceptedBy.includes(activeUserWorkstation.value?.id)
    ) {
      return true;
    }
    return false;
  });

  const currFinal = currCon.filter((el: any) => {
    let acceptedBy = null;
    if (el.acceptedBy == null) {
      acceptedBy = [];
    } else {
      if (Array.isArray(el.acceptedBy)) {
        acceptedBy = el.acceptedBy;
      } else {
        if (
          typeof el.acceptedBy == "string" &&
          JSON.parse(el.acceptedBy) &&
          Array.isArray(JSON.parse(el.acceptedBy))
        ) {
          acceptedBy = JSON.parse(el.acceptedBy);
        } else {
          acceptedBy = [];
        }
      }
    }
    if (
      el.assignPhaseWorkStation &&
      [EstimatePhaseStatus.PENDING, EstimatePhaseStatus.WAITING].includes(
        el.phaseStatus
      )
    ) {
      return true;
    }

    if (
      !el.assignPhaseWorkStation &&
      [EstimatePhaseStatus.ACCEPTED].includes(el.phaseStatus) &&
      el.workStation.id == activeUserWorkstation.value?.id
    ) {
      return false;
    }

    if (
      [EstimatePhaseStatus.ACCEPTED].includes(el.phaseStatus) &&
      !acceptedBy.includes(activeUserWorkstation.value?.id)
    ) {
      return true;
    }
    return false;
  });

  return [...subFinal, ...currFinal];
};

const updateDepositedPhases = async () => {
  // eslint-disable-next-line
  try {
    if (depositModalData.value) {
      await projectEstimationService.depositPhaseWithEstimateUpdate(
        formData.value?.id,
        depositModalData.value
      );
    } else {
      ElMessage.error("deposits data is null, please select again");
    }
  } catch (error) {
    throw error;
  }
};

const submitEstimate = async () => {
  try {
    if (isSubContractor.value) {
      await projectEstimationService.submitEstimateBySubContractor(
        projectEstimateInvitation.value[0]?.id
      );
      store.dispatch(`${JOB_TEMPLATE_STORE}/setIsEstimateSubmit`, true);
      emits("onFinalSave");
      return true;
    } else {
      finalEstimateLoadingModal.visible = true;
      await updateDepositedPhases();
      await updateMainContractorPhaseStatus();
      const payload = {
        status: AgreeTermsConsumerDisclosure.PENDING,
        totalEstimateCost:
          totalSubTotal.value + totalVat.value + serviceCharges.value,
      };
      const response =
        (await projectEstimationService.submitStatusWithEstimateUpdate(
          props.project?.id,
          formData.value?.id,
          payload
        )) as any;

      if (response) {
        if (props.project?.projectMetaData !== null) {
          await store.dispatch(`${PROJECT_STORE}/updateRaiseProjectStatus`, {
            projectId: props.project?.id,
            data: {
              status: ProjectMetaDataStatusEnum.PENDING,
            },
          });
          await store.dispatch(
            `${JOB_TEMPLATE_STORE}/setLockTemplateModifiedChecker`,
            true
          );
        }
      }
      setTimeout(() => {
        finalEstimateLoadingModal.step = 1;
      }, 1500);
      return true;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

const updateMainContractorPhaseStatus = async () => {
  estimateForm.value.projectStages.forEach(async (phase: any) => {
    if (
      phase?.workStation?.id === activeUserWorkstation.value?.id &&
      !phase?.assignPhaseWorkStation?.length &&
      !phase.phaseStatus
    ) {
      await projectEstimationService.acceptEstimatePhase(phase.id, {
        accptedData: [phase?.workStation?.id],
      });
    }
  });
};
const onFinalEstimateSubmitLoadingCancel = () => {
  finalEstimateLoadingModal.visible = false;
  finalEstimateLoadingModal.step = 0;
  let showSiteVisitRequest = false;
  if (
    depositModalData.value &&
    [
      EstimateTypeForDeposit.Variable_Cost_Estimation,
      EstimateTypeForDeposit.Fixed_Cost_Estimation,
    ].includes(depositModalData.value.depositType)
  ) {
    showSiteVisitRequest = true;
  }
  emits("onFinalSave", { showSiteVisitRequest });
};
</script>
<style lang="scss" scoped>
.historic-phase-content {
  padding: 8px !important;
  background-color: rgba($white, 1) !important;
  border: 1px solid rgba($bordercolor, 1);
  border-radius: 8px !important;
}

.estimate_summary__info__list {
  &__item {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .items_list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px;
      border: 1px solid rgba($bordercolor, 1);
      border-radius: 8px;
      background-color: rgba($PrimaryPurple, 0.04);
      .items_cost {
        @include fluidFont(12, 12, 2);
        font-weight: 700;
        color: rgba($blueDark, 1);
        letter-spacing: 0.25px;
        display: flex;
        align-items: center;
        gap: 16px;
        span {
          font-weight: 400;
        }
      }
    }
  }
}

.range_input {
  :deep(.v-input__control) {
    .v-field {
      .v-field__field {
        .v-label {
          font-size: 14px;
          line-height: 1.2;
          font-weight: 600;
          color: rgba($blueDark, 0.5);
        }
        .v-field__input {
          font-size: 14px;
          line-height: 1.2;
          font-weight: 600;
          color: rgba($blueDark, 1);
          min-height: auto;
          padding: 4px 12px;
          &::placeholder {
            font-size: 14px;
            line-height: 1.2;
            font-weight: 600;
            color: rgba($blueDark, 0.5);
          }
        }
      }
      .v-field__outline {
        display: none;
      }
    }
  }
}

.item {
  padding: 0 8px;
  border-radius: 5px;
  background-color: rgba($blueDark, 0.1);
  max-width: max-content;
  @include fluidFont(10, 10, 2);
  font-weight: 700;
  color: rgba($blueDark, 1);
  letter-spacing: 0.25px;
}
</style>
