<template>
  <GeneralDialog
    :headerTitle="modalHeader.title"
    :headerDescription="modalHeader.description"
    :headerTitleFontSize="18"
    @on-close="onCloseModal"
    height="680px"
    width="670px"
  >
    <template #body>
      <div class="tw-flex tw-items-center tw-gap-2 tw-mb-4 sm:tw-flex-col">
        <div class="tw-w-1/2 tw-flex tw-items-center tw-gap-2 sm:tw-w-full">
          <v-text-field
            v-model="filterObject.experience"
            placeholder="Experience"
            type="number"
            min="0"
            class="c-input input_field location_field"
            bg-color="#fff"
            variant="outlined"
            suffix="Years"
          >
            <template v-slot:prepend-inner>
              <img :src="require('@/assets/icons/trade-network-list.svg')" />
            </template>
          </v-text-field>
        </div>
        <div class="selector tw-w-1/2 sm:tw-w-full">
          <v-select
            v-model="filterObject.qualifications"
            :items="listOfCategories"
            bg-color="#fff"
            variant="outlined"
            placeholder="Painting & Decorating"
            item-value="id"
            item-title="tpLabel"
            class="c-input input_field"
            :menu-props="{ contentClass: 'service__selector' }"
            @update:modelValue="handleSelection"
          >
            <template v-slot:prepend-inner>
              <img
                class="tw-w-[10px] tw-h-[10px]"
                v-if="selectedCategoryIcon"
                :src="
                  require(`@/assets/category-icons/${selectedCategoryIcon}.svg`)
                "
              />
              <img v-else src="@/assets/icons/painting.svg" />
            </template>
            <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props">
                <div>
                  <img
                    class="tw-w-[10px] tw-h-[10px]"
                    v-if="item.iconName"
                    :src="
                      require(`@/assets/category-icons/${item.iconName}.svg`)
                    "
                    alt="Image"
                  />
                  <span>{{ item.iconName }}</span>
                </div>
              </v-list-item>
            </template>
          </v-select>
        </div>
      </div>

      <div class="w-full">
        <v-text-field
          placeholder="Search Workstation Names"
          class="c-input input_field search_wrapper"
          bg-color="#fff"
          variant="outlined"
          v-model="filterObject.search"
        >
          <template v-slot:prepend-inner>
            <img src="@/assets/icons/trade-network-search.svg" />
          </template>
        </v-text-field>
      </div>
      <div class="results_wrapper">
        <div class="results_wrapper__text">
          <strong> {{ getUserNetworkConnectedList?.length }}</strong> Results
          |<strong> {{ selectedContractorsId.length }}</strong>
          Selected
        </div>
      </div>
      <Text>Your Trader Network</Text>
      <div class="contractor_cards">
        <div class="contractor_cards_list">
          <CommonLoader :loading="loading" class="common_loader" />
          <span
            v-if="!loading && !paginatedContractors.length"
            class="empty_workstation"
          >
            No trade network available at the moment.
          </span>
          <v-card
            v-for="contractor of paginatedContractors"
            :key="contractor.id"
            class="contractor_card"
            :class="
              isSelectedNetworkContractor(contractor?.receiverWorkstation?.id)
                ? 'selected'
                : ''
            "
            @click="
              onClickNetworkContractorCard(
                contractor?.receiverWorkstation?.id,
                contractor?.receiverWorkstation?.user?.id
              )
            "
          >
            <div class="left_block">
              <span
                :class="
                  isUserWorkStationOnline(contractor?.id)
                    ? 'online_status'
                    : 'offline_status'
                "
              ></span>

              <img
                v-if="contractor?.receiverWorkstation?.profileImageUrl"
                :src="contractor?.receiverWorkstation?.profileImageUrl"
                alt=""
              />

              <div v-else class="left_block__profile_img profile_img">
                {{ getWSNameLogoChar(contractor?.receiverWorkstation?.name) }}
              </div>
            </div>
            <div class="right_block">
              <div class="title">
                <h5>{{ contractor?.receiverWorkstation?.name }}</h5>
                <div class="subtitle">
                  <p>
                    {{
                      contractor?.receiverWorkstation.user?.userBusinessDetails
                        ?.type === BusinessDetailTypeEnum.LIMITED_COMPANY
                        ? "Limited Company"
                        : "Solo Trader"
                    }}
                    <!-- <span>|</span> £22/hr -->
                  </p>
                </div>
              </div>
              <div class="icon_boxes">
                <div class="icon_box">
                  <div class="icon_box__icon">
                    <img src="@/assets/images/rating.svg" alt="" />
                  </div>
                  <p>
                    {{ contractor?.ratings }}/5 ({{ contractor?.jobsCompleted }}
                    Jobs)
                  </p>
                </div>
              </div>
              <div class="icon_boxes">
                <div class="status icon_box" v-if="contractor?.isAvailable">
                  <div class="icon_box__icon">
                    <img
                      :src="require('@/assets/icons/check-mark-sucess.svg')"
                    />
                  </div>
                  <p>Currently Available</p>
                </div>
                <div class="status icon_box" v-if="!contractor?.isAvailable">
                  <div class="icon_box__icon">
                    <img :src="require('@/assets/icons/available.svg')" />
                  </div>
                  <p>Currently Not Available</p>
                </div>
              </div>
            </div>
          </v-card>
        </div>
      </div>
      <div class="custom_pagination" v-if="!loading">
        <ul>
          <li
            v-for="page in totalPages"
            :key="page"
            :class="{ active: currentPage === page }"
            class="page-number"
          >
            <a @click.prevent="goToPage(page)">{{ page }}</a>
          </li>
        </ul>

        <v-btn
          @click="onclickContinue"
          class="button button-orange"
          :disabled="!selectedContractorsId.length"
          >CONTINUE</v-btn
        >
      </div>
    </template>
    <template #footer>
      <div
        class="tw-flex tw-gap-2 tw-items-center tw-justify-between sm:tw-flex-col"
      >
        <div class="sm:tw-w-full">
          <Button
            variant="secondary"
            label="Search for a new contractor"
            @click="onOpenSelectContractorModal"
          />
        </div>
        <Text
          class="tw-cursor-pointer"
          variant="span"
          textColor="#FFA500"
          textWeight="500"
          @click="inviteContractorFromOutSideIknowa"
          >Invite contractor from outside Iknowa</Text
        >
      </div>
    </template>
  </GeneralDialog>
</template>
<script lang="ts" setup>
import { computed, watch, onBeforeMount, ref } from "vue";
import GeneralDialog from "@/core/components/modals/GeneralDialog.vue";
import Button from "@/core/components/ui/general/Button.vue";
import Text from "@/core/components/ui/general/Text.vue";
import { TRADE_NETWORK_ROUTE } from "@/modules/trade-network/routes";
import { USER_STORE } from "@/store/modules/user";
import { useStore } from "vuex";
import { WORKSTATION } from "@/store/modules/workstation";
import { TRADE_NETWORK } from "@/store/modules/trade-network";
import { BusinessDetailTypeEnum } from "@/core/enums/RolesEnum";
import userService from "@/core/services/user.service";
import { JOB_TEMPLATE_STORE } from "@/store/modules/job-template";
import CommonLoader from "@/core/components/CommonLoader.vue";
import { useRouter } from "vue-router";
const props = defineProps(["selectedContractorList", "projectData"]);
const emits = defineEmits([
  "openOutsiderInviteModel",
  "onclickContinue",
  "on-close",
]);
const modalHeader = {
  title: "Select a contractor to invite to your estimate ",
  description:
    "Choose from your created groups below, or search for a new contractor.",
};

const store = useStore();
const router = useRouter();
const selectedContractorsId = ref<number[]>(
  props.selectedContractorList
) as any;

const listOfQualifications = ref([]) as any;

const listOfCategories = ref([]) as any;

const userRates = ref([]) as any;

const qualifications = ref("");

const filterObject = ref({
  postcode: null,
  search: "",
  radius: null,
  qualifications: null,
  experience: null,
}) as any;

const currentPage = ref(1);
const itemsPerPage = ref(4);
const loading = ref(false);
const onClickNetworkContractorCard = (contractorId: number, userId: number) => {
  if (isSelectedNetworkContractor(contractorId)) {
    selectedContractorsId.value = selectedContractorsId.value?.filter(
      (contractor: any) => contractor.toContractorId !== contractorId
    );
  } else {
    selectedContractorsId.value.push({
      toContractorId: contractorId,
      toUserId: userId,
    });
  }
};
const isSelectedNetworkContractor = (contractorId: number) =>
  selectedContractorsId.value.find(
    (contractor: any) => contractor.toContractorId === contractorId
  );

const onOpenSelectContractorModal = () => {
  store.dispatch(`${TRADE_NETWORK}/setInviteContractorFromTradeNetwork`, true);
  store.dispatch(`${TRADE_NETWORK}/setInviteContractorProjectData`, {
    projectData: props.projectData,
  });
  router.push({ name: TRADE_NETWORK_ROUTE });
};

const activeUserWorkstation = computed(
  () => store.getters[`${WORKSTATION}/activeUserWorkstation`]
);

const onlineUser = computed(
  () => store.getters[`${WORKSTATION}/getUserOnlineWorkStations`]
);

const filteredContractors = computed(() => {
  return getUserNetworkConnectedList.value;
});
const totalPages = computed(() => {
  return Math.ceil(filteredContractors.value.length / itemsPerPage.value);
});
const selectedCategoryIcon = ref(null);

const paginatedContractors = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage.value;
  const end = start + itemsPerPage.value;
  return filteredContractors.value.slice(start, end);
});
const goToPage = (page: any) => {
  currentPage.value = page;
};
const getUserNetworkConnectedList: any = computed(() => {
  const invited = [] as any;
  const tradeNetwork =
    store.getters[`${TRADE_NETWORK}/getUserNetworkConnectedList`];
  store.getters[`${JOB_TEMPLATE_STORE}/invitedContractorsList`]?.map(
    (el: any) => {
      invited.push(el.fromContractorId);
      invited.push(el.toContractorId);
    }
  );

  return tradeNetwork.filter(
    (el: any) => !invited.includes(el.receiverWorkstation.id)
  );
});

const isUserWorkStationOnline = (wsId: number) => {
  const isExist = onlineUser.value?.find(
    (data: any) => data?.userWorkstations?.id === wsId
  );
  return isExist ? true : false;
};

const inviteContractorFromOutSideIknowa = () => {
  emits("openOutsiderInviteModel");
};

const onclickContinue = () => {
  emits("onclickContinue", selectedContractorsId.value);
};

const onCloseModal = () => {
  emits("on-close");
};

const handleSelectionChange = () => {
  qualifications.value = listOfQualifications?.value;
};
const handleSelection = (selectedValue: any) => {
  const selected = listOfCategories.value?.find(
    (item: any) => item.id === selectedValue
  );
  selectedCategoryIcon.value = selected ? selected.iconName : null;
};

watch(
  () => listOfQualifications.value,
  async (newValue, oldValue) => {
    if (newValue) {
      handleSelectionChange();
    }
  }
);

watch(
  () => filterObject.value,
  async (newValue) => {
    try {
      const params = Object.fromEntries(
        Object.entries(newValue)?.filter((value: any) => {
          return value[1] === 0 ? true : value[1];
        })
      );
      await store.dispatch(`${TRADE_NETWORK}/setUserNetworkConnections`, {
        activeUserWorkstationId: activeUserWorkstation.value?.id,
        params,
      });
    } catch (error) {
      console.log("error :>> ", error);
    }
  },
  { deep: true }
);

const getWSNameLogoChar = (WSName: string) => {
  if (WSName) {
    const initials = WSName.split(" ")
      .map((word) => word.charAt(0))
      .join("");
    return initials;
  }
  return "";
};
onBeforeMount(async () => {
  try {
    loading.value = true;
    if (!getUserNetworkConnectedList.value.length)
      await store.dispatch(`${TRADE_NETWORK}/setUserNetworkConnections`, {
        activeUserWorkstationId: activeUserWorkstation.value?.id,
      });

    loading.value = false;
    listOfCategories.value = await store.dispatch(
      `${USER_STORE}/getCategories`
    );

    if (listOfCategories.value.length) {
      const defaultObj = {
        id: null,
        iconName: "",
        isApprovedByAdmin: false,
        isCustom: false,
        poLabel: "None",
        subCategories: [],
        tpLabel: "None",
      };
      listOfCategories.value.push(defaultObj);
    }

    userRates.value = await userService.getUserTradeRates();
    if (userRates.value.length) {
      userRates.value = userRates.value.map((userRate: any) => {
        return {
          ...userRate,
          rateName: `${userRate?.minRate}-${userRate?.maxRate}`,
        };
      });
    }
  } catch (error) {
    console.log();
  } finally {
    loading.value = false;
  }
});
</script>
<style lang="scss" scoped>
.location_field {
  width: 50%;
  max-width: 100%;
}
.miles_field {
  width: 50%;
  max-width: 100%;
}
.c-input {
  margin: 0;
  :deep(.v-input__control) {
    .v-field {
      padding: 0 16px;
      border: 1px solid rgba($buttonText, 0.4);
      border-radius: 8px;
      gap: 8px;
      .v-field__field {
        .v-field__input {
          background-color: transparent;
          min-height: 42px;
          padding: 0;
        }
        .v-text-field__suffix {
          padding: 0;
          min-height: 42px;
        }
      }
    }
  }
}

:deep(.v-select .v-field .v-field__input > input) {
  align-self: center;
}
.search_wrapper {
  :deep(.v-input__control) {
    .v-field {
      border: none;
      border-radius: 30px;
      background-color: rgba($blueDark, 0.04) !important;
    }
  }
}
.results_wrapper {
  margin: 16px 0 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
  .results_wrapper__text {
    @include fluidFont(12, 12, 1.2);
    letter-spacing: 0.4px;
    color: rgba($blueDark, 1);
  }
}

.contractor_cards {
  display: flex;
  gap: 16px;
  align-items: flex-start;
  flex-wrap: wrap !important;
  // min-height: 237px;
  max-height: 260px;
  overflow: auto;
  @include respond(s720) {
    max-height: 260px;
  }
  .contractor_cards_list {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    .contractor_card {
      padding: 12px 16px;
      box-shadow: 0px 0px 8px 0px #0c0f4a14;
      display: flex;
      gap: 15px;
      align-items: flex-start;
      border-radius: 8px;
      cursor: pointer;
      width: calc(50% - 8px);
      max-width: 100%;
      @include respond(s720) {
        width: 100%;
      }
      .left_block {
        position: relative;
        w .left_block__profile_img {
          width: 36px;
          height: 36px;
          border-radius: 100%;
          background: rgba($blueDark, 1);
          overflow: hidden;
          color: rgba($white, 1);
          display: flex;
          align-items: center;
          justify-content: center;
          @include fluidFont(21, 21, 1.2);
          font-weight: 700;
        }
        img {
          width: 36px;
          height: 36px;
          object-fit: cover;
          vertical-align: middle;
          border-radius: 100%;
        }
        .online_status {
          width: 12px;
          height: 12px;
          border-radius: 100%;
          background-color: #3bc740;
          border: 1px solid rgba($white, 1);
          position: absolute;
          right: 0;
          top: 0;
        }
        .offline_status {
          width: 12px;
          height: 12px;
          border-radius: 100%;
          background-color: rgba($errorRed, 1);
          border: 1px solid rgba($white, 1);
          position: absolute;
          right: 0;
          top: 0;
        }
      }
      .right_block {
        width: calc(100% - 70px);
        max-width: 100%;
        .title {
          padding-bottom: 5px;
          border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
          h5 {
            @include fluidFont(16, 16, 1.2);
            font-weight: 700;
            color: rgba($blueDark, 1);
          }
          .subtitle {
            margin-top: 4px;
            p {
              @include fluidFont(12, 12, 1.2);
              font-weight: 700;
              color: rgba($blueDark, 0.5);
              span {
                display: inline-block;
                margin: 0 6px;
              }
            }
          }
        }
        .icon_boxes {
          padding-top: 10px;
          .icon_box {
            display: flex;
            align-items: center;
            gap: 5px;
            .icon_box__icon {
              width: 16px;
              height: 16px;
              img {
                filter: invert(58%) sepia(61%) saturate(727%) hue-rotate(2deg)
                  brightness(102%) contrast(100%);
                width: 100%;
                object-fit: cover;
                vertical-align: super;
                height: 100%;
              }
            }

            p {
              @include fluidFont(12, 12, 1.2);
              font-weight: 400;
              color: rgba($blueDark, 1);
            }
          }
          .status.icon_box {
            .icon_box__icon {
              img {
                filter: unset;
              }
            }
          }
          .online_status_wrapper {
            margin-top: 4px;
            .status_img {
              img {
                filter: none;
              }
            }
            p {
              font-weight: 700;
            }
          }
        }
      }
    }
    .selected {
      border: 2px solid rgba($orange, 1);
    }
    .common_loader {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.profile_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.custom_pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid rgba($PrimaryBlueLight, 1);
  @include respond(sm) {
    flex-direction: column;
    gap: 8px;
    padding: 8px 0;
  }
  ul {
    list-style-type: none;
    display: flex;
    gap: 4px;
    align-items: center;
    @include respond(sm) {
      gap: 8px;
      padding: 8px 0;
      width: 100%;
      justify-content: center;
    }
    .page-number {
      a {
        @include fluidFont(12, 12, 1.2);
        letter-spacing: 0.15px;
        color: rgba($blueDark, 0.5);
        text-decoration: none;
        font-weight: 400;
      }
      .active {
        color: rgba($blueDark, 1);
        font-weight: 700;
      }
    }
  }
  .button {
    @include respond(sm) {
      width: 100%;
      max-width: 100%;
    }
  }
}

.estimate_modal {
  .v-overlay__content {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.common_loader {
  height: calc(100% - 370px);
  width: calc(100% - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
}
.empty_workstation {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
